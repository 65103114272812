import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { getUnixTime } from "date-fns";
import { fireSnake } from "./globalsnakebar";
import {
  calculateDiscount,
  calculateSingleTotalWithTax,
  calculateTaxTotal,
} from "../../util/calculators";
import i18n from "../../i18n";

const loading = () => {
  return {
    type: actionTypes.OFFERS_LOADING,
  };
};

const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};

const setErrors = (err) => {
  return {
    type: actionTypes.OFFERS_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};

/*Offers */
export const getOfferInvoice = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(
        `/pricelist/${id}?expand=customer,items,saledBy,repairedBy`
      );
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getOffers = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "invoice_date";
    let orderDirection = "-";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "invoice_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/pricelist?expand=customer,items,saledBy,repairedBy&per-page=${
          query.pageSize
        }&page=${query.page + 1}&sort=${orderDirection + orderQuery}`
      );
      dispatch({
        type: actionTypes.SET_OFFERS,
        offers: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addOfferItem = (customerInfo, productInfo, selected) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      if (!selected) {
        //New draft
        const draftBody = {
          status: 0,
          ...getInvoiceValues({
            ...customerInfo,
            store_id: productInfo.store_id,
          }),
        };
        const draft = await axios.post(
          `/pricelist?expand=items,customer`,
          draftBody
        );
        const itemBody = {
          invoice_id: draft.data.id,
          ...getProductValues(productInfo),
        };
        const item = await axios.post(`/pricelistitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...draft.data, items: [...draft.data.items, item.data] };
      } else {
        //Existing draft
        const itemBody = {
          invoice_id: selected.id,
          ...getProductValues(productInfo),
        };
        const item = await axios.post(`/pricelistitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...selected, items: [...selected.items, item.data] };
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const editOfferItem = (productInfo, id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      const editedItem = await axios.put(
        `/pricelistitem/${id}`,
        getProductValues(productInfo)
      );
      dispatch({ type: actionTypes.UNLOAD });
      return editedItem.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteOfferItem = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      await axios.delete(`/pricelistitem/${id}`);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveOfferInvoice = (customerInfo, totals, type, id) => {
  //type = 0 draft | type = 1 export
  return async (dispatch) => {
    dispatch(loading());
    try {
      const InvoiceInfo = {
        status: type,
        ...getInvoiceValues(customerInfo),
        ...totals,
        //brutto_no_tax:0 //TODO This was told its for later
      };
      await axios.put(`/pricelist/${id}`, InvoiceInfo);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteOfferInvoice = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("pricelist/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

let timeout;
export const searchOffersCustomers = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(`/pricelist/clist?q=${query ? query : ""}`);
        dispatch({
          type: actionTypes.OFFERS_CUSTOMERS_SEARCH,
          customers: res.data,
        });
      } catch (err) {
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(err.response ? err.response.data : err));
        throw err.response ? err.response.data : err;
      }
    }, 1500);
  };
};
export const searchOfferProducts = (search, storeId, customer_id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);
    let query = `?q=${search}&store_id=${storeId}&customer_id=${customer_id}`;
    if (!customer_id) {
      query = `?q=${search}&store_id=${storeId}`;
    }
    timeout = await setTimeout(async () => {
      try {
        const res = await axios.get(`/pricelist/plist${query}`);

        dispatch({
          type: actionTypes.OFFERS_PRODUCT_SEARCH,
          products: res.data,
        });
      } catch (err) {
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(err.response ? err.response.data : err));
        throw err.response ? err.response.data : err;
      }
    }, 1500);
  };
};
export const clearSearchOfferProduct = () => {
  return {
    type: actionTypes.OFFERS_PRODUCT_SEARCH,
    products: [],
  };
};
export const getOfferPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`pricelist/permissions`);
      dispatch({
        type: actionTypes.SET_OFFERS_PERMISSIONS,
        offersPermisssoins: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveOfferPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`pricelist/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_OFFERS_PERMISSIONS,
          offersPermisssoins: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

//Helpers
const getInvoiceValues = (invoiceInfo) => {
  //   const checkPaymentCash = () =>
  //     invoiceInfo.paid_type.value === "cash" ||
  //     invoiceInfo.paid_type.value === "postpay";
  return {
    customer_id: invoiceInfo.customer.value
      ? invoiceInfo.customer.value.id
      : "",
    store_id: invoiceInfo.store_id.value,
    saled_by: invoiceInfo.customer.value
      ? invoiceInfo.customer.value.salesman
      : "",
    invoice_date: getUnixTime(invoiceInfo.invoice_date.value),
    //   h_invoice_nr: invoiceInfo.h_invoice_nr.value,
    //   paid_type: invoiceInfo.paid_type.value,
    //   check_nr: invoiceInfo.check_nr.value,
    //   check_date: checkPaymentCash()
    //     ? ""
    //     : getUnixTime(invoiceInfo.check_date.value),
    //   bank_id: checkPaymentCash() ? "" : invoiceInfo.bank_id.value,
    description: invoiceInfo.description.value,
  };
};
const getProductValues = (item) => {
  const discount = calculateDiscount(item.discount.value, item.quantity.value);
  const SingleTotalWithTax = calculateSingleTotalWithTax(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  const TaxTotal = calculateTaxTotal(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  return {
    title: item.title.value,
    title_state: item.title_state.value,
    product_id: item.product.value.id,
    quantity: item.quantity.value,
    price_type: item.price_type.value,
    unit_price: item.unit_price.value,
    tax_id: item.product.value.tax_id,
    tax_total: TaxTotal,
    discount: discount,
    total: SingleTotalWithTax,
  };
};

const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", i18n.t("common:uncommonerror")));
  };
};

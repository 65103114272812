import axiosAuth from "axios";

export const axios = axiosAuth.create({
  // baseURL: "http://tijary.lan/api/v1",
  baseURL: "https://m7w.com/api/v1",
  timeout: 10000,
  headers: {
    Expires: "0",
    "Cache-Control": "no-cache, no-store",
    Control: "must-revalidate max-age=0",
  },
});

export const setAuthHeader = (token, cb) => {
  if (!token) {
    delete axios.defaults.headers.common["Authorization"];
    return cb();
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return cb();
};

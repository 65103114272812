import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import {
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList,
  Line,
  ReferenceLine,
  Brush,
} from "recharts";
import { useTranslation } from "react-i18next";
import PieSkeleton from "../../../../components/UI/Spinners/PieSkeleton";
import { useDispatch } from "react-redux";
import {
  getFiscalYearStats,
  getFiscalMonthlyStats,
} from "../../../../store/actions";
import SelectInput from "../../../../components/UI/Inputs/SelectInput";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";
import { arSA } from "date-fns/locale";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import { format } from "date-fns";
import ChartSkeleton from "../../../../components/UI/Spinners/ChartSkeleton";

const FiscalChart = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [type, setType] = useState("year");
  const [date, setDate] = useState({
    year: new Date().getFullYear(),
    month: +format(new Date(), "M"),
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width } = useWindowResize();
  const getFiscalStat = useCallback(async () => {
    try {
      setLoading(true);

      if (type === "year") {
        const yearStats = await dispatch(getFiscalYearStats());
        setData(yearStats);
      } else {
        const monthStats = await dispatch(getFiscalMonthlyStats(date.year));
        setData(monthStats);
      }
    } catch (err) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, type, date]);

  useEffect(() => {
    getFiscalStat();
  }, [getFiscalStat]);

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item sm={4} xs={6}>
          <SelectInput
            label={t("statistics:period")}
            options={[
              { value: "year", text: t("common:yearly") },
              { value: "month", text: t("common:monthly") },
            ]}
            initialValue={type}
            getResult={(type) => setType(type)}
          />
        </Grid>
        <Grid item sm={4} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={arSA}>
            <DatePicker
              value={new Date(date.year, date.month - 1, 1)}
              // label={
              //   type === "month"
              //     ? t("statistics:choose_year_month")
              //     : t("statistics:choose_year")
              // }
              label={t("statistics:choose_year")}
              onChange={(e) =>
                setDate({
                  year: new Date(e).getFullYear(),
                  month: +format(new Date(e), "M"),
                })
              }
              // style={{ width: "100%" }}
              inputVariant="outlined"
              cancelLabel={t("common:cancel")}
              okLabel={t("common:confirm")}
              // format={type === "month" ? "yyyy MMMM" : "yyyy"}
              format={"yyyy"}
              InputProps={{
                endAdornment: <TodayRoundedIcon color="disabled" />,
              }}
              // views={type === "month" ? ["year", "month"] : ["year"]}
              views={["year"]}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Typography align="center" variant="h4">
        {t("statistics:fiscal_chart_title")}
      </Typography>
      <div style={{ width: "100%", height: width < 960 ? 350 : 330 }}>
        {loading ? (
          type === "year" ? (
            <PieSkeleton />
          ) : (
            <ChartSkeleton />
          )
        ) : type === "year" ? (
          <AnnualChart data={data} year={date.year} />
        ) : (
          <MonthlyChart data={data} year={date.year} month={date.month} />
        )}
      </div>
    </Fragment>
  );
};

const RADIAN = Math.PI / 180;
const AnnualChart = ({ data, year }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { width } = useWindowResize();

  const fixAnnualData = () => {
    let _data = [];

    if (!data.length) {
      return _data;
    }

    for (const d of data) {
      if (+d.year === year) {
        _data.push(
          { text: "sales", value: +d.sales },
          { text: "resales", value: +d.resales },
          { text: "purchases", value: +d.purchases },
          { text: "repurchases", value: +d.repurchases },
          { text: "expense", value: +d.expense }
          // { text: "balance", value: d.balance }
          // { text: "year", value: +d.year }
        );
        break;
      }
    }

    return _data;
  };

  const getBalance = () => {
    if (!data.length) {
      return [{ text: "balance", value: 0, isNegative: true }];
    }
    let _balance = [];
    for (const dat of data) {
      if (+dat.year === year) {
        _balance.push({
          text: "balance",
          value: +dat.balance < 0 ? +dat.balance * -1 : +dat.balance,
          isNegative: +dat.balance < 0,
        });
        break;
      }
    }

    return _balance;
  };
  const COLORS = [
    theme.palette.primary.dark,
    theme.palette.primary.light,
    theme.palette.secondary.dark,
    theme.palette.secondary.light,
    theme.palette.warning.dark,
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {percent > 0 ? `(${(percent * 100).toFixed(2)}%)` : ""}
      </text>
    );
  };
  const renderBalanceLabel = (props) => {
    const { cx, cy, midAngle, outerRadius, fill, value, isNegative } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    // const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 1}
          y={ey - 5}
          textAnchor="middle"
          fill="#333"
        >
          {`${isNegative ? value * -1 : value}`}
        </text>
      </g>
    );
  };

  return fixAnnualData().length ? (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          data={fixAnnualData()}
          labelLine={false}
          label={renderCustomizedLabel}
          fill="#8884d8"
          dataKey="value"
          outerRadius={120}
        >
          {fixAnnualData().map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>

        <Pie
          data={getBalance()}
          dataKey="value"
          innerRadius={130}
          outerRadius={140}
          fill={
            getBalance()[0] && +getBalance()[0].isNegative
              ? theme.palette.error.dark
              : theme.palette.success.dark
          }
          label={renderBalanceLabel}
        />
        <Legend
          iconSize={15}
          layout={width < 960 ? "horizontal" : "vertical"}
          verticalAlign={width < 960 ? "bottom" : "middle"}
          align={width < 960 ? "center" : "left"}
          wrapperStyle={{ direction: "ltr" }}
          formatter={(value, entry, index) => {
            return (
              <Typography variant="caption" style={{ margin: "0 3px" }}>
                {`${t(`statistics:${entry.payload.text}`)} (${
                  entry.payload.isNegative
                    ? entry.payload.value * -1
                    : entry.payload.value
                })`}
              </Typography>
            );
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <Box className="flex__centerlize fullheight">
      <Typography>{t("statistics:no_statistics_period")}</Typography>
    </Box>
  );
};

/**
 * Monthly Bar
 */
const MonthlyChart = ({ data, year }) => {
  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();

  const fixDataFor12Months = () => {
    const months = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
    const _data = months.map((month) => {
      const hasMonth = data.findIndex((d) => d.month === month);
      if (hasMonth >= 0) {
        return {
          ...data[hasMonth],
          month: t(`statistics:${month}`),
        };
      }
      return {
        sales: 0,
        resales: 0,
        purchases: 0,
        repurchases: 0,
        expense: 0,
        balance: 0,
        month: t(`statistics:${month}`),
        year: year,
      };
    });
    return _data;
  };

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={fixDataFor12Months()}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        style={{ direction: "ltr", fontFamily: "Cairo" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" angle={-45} dx={-10} dy={5} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Brush
          dataKey="month"
          height={20}
          stroke={theme.palette.grey[700]}
          // onChange={(e) => {
          //   if((e.endIndex - e.startIndex) < 3){

          //   }
          // }}
        />
        <ReferenceLine y={0} stroke="#000" />
        <Bar
          dataKey="sales"
          // stackId="a"
          fill={theme.palette.primary.dark}
          name={t("statistics:sales")}
        >
          <LabelList
            position="top"
            dataKey="sales"
            fill={theme.palette.text.secondary}
            className={classes.label}
            // angle={-90}
            formatter={(value) => (value > 0 ? value : "")}
          />
        </Bar>
        <Bar
          dataKey="resales"
          // stackId="b"
          fill={theme.palette.primary.light}
          name={t("statistics:resales")}
        >
          <LabelList
            position="top"
            dataKey="resales"
            fill={theme.palette.text.secondary}
            className={classes.label}
            formatter={(value) => (value > 0 ? value : "")}
          />
        </Bar>
        <Bar
          dataKey="purchases"
          // stackId="b"
          fill={theme.palette.secondary.dark}
          name={t("statistics:purchases")}
        >
          <LabelList
            position="top"
            dataKey="purchases"
            fill={theme.palette.text.secondary}
            className={classes.label}
            formatter={(value) => (value > 0 ? value : "")}
          />
        </Bar>
        <Bar
          dataKey="repurchases"
          // stackId="a"
          fill={theme.palette.secondary.light}
          name={t("statistics:repurchases")}
        >
          <LabelList
            position="top"
            dataKey="repurchases"
            fill={theme.palette.text.secondary}
            className={classes.label}
            formatter={(value) => (value > 0 ? value : "")}
          />
        </Bar>
        <Bar
          dataKey="expense"
          // stackId="b"
          fill={theme.palette.warning.dark}
          name={t("statistics:expense")}
        >
          <LabelList
            position="top"
            dataKey="expense"
            fill={theme.palette.text.secondary}
            className={classes.label}
            formatter={(value) => (value > 0 ? value : "")}
          />
        </Bar>
        <Line
          type="monotone"
          dataKey="balance"
          stroke={theme.palette.error.dark}
          strokeWidth={2}
          name={t("reports:amount")}
          baselineShift=""
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const useStyle = makeStyles((theme) => ({
  label: {
    fontSize: 10,
  },
}));
/**
 * Monthly Pie
 */
// const MonthlyChart = ({ data, year, month }) => {
//   const theme = useTheme();
//   const { t } = useTranslation();
//   const { width } = useWindowResize();

//   const fixMonthlyData = () => {
//     let _data = [];

//     if (!data.length) {
//       return _data;
//     }

//     for (const d of data) {
//       if (+d.year === year && +d.month === month) {
//         _data.push(
//           { text: "sales", value: +d.sales },
//           { text: "resales", value: +d.resales },
//           { text: "purchases", value: +d.purchases },
//           { text: "repurchases", value: +d.repurchases },
//           { text: "expense", value: +d.expense }
//           // { text: "balance", value: d.balance }
//           // { text: "year", value: +d.year }
//         );
//         break;
//       }
//     }

//     return _data;
//   };

//   const getBalance = () => {
//     if (!data.length) {
//       return [{ text: "balance", value: 0, isNegative: true }];
//     }
//     let _balance = [];
//     for (const dat of data) {
//       if (+dat.year === year && +dat.month === month) {
//         _balance.push({
//           text: "balance",
//           value: +dat.balance < 0 ? +dat.balance * -1 : +dat.balance,
//           isNegative: +dat.balance < 0,
//         });
//         break;
//       }
//     }

//     return _balance;
//   };
//   const COLORS = [
//     theme.palette.primary.dark,
//     theme.palette.primary.light,
//     theme.palette.secondary.dark,
//     theme.palette.secondary.light,
//     theme.palette.warning.dark,
//   ];

//   const renderCustomizedLabel = ({
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     percent,
//     value,
//   }) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);

//     return (
//       <text
//         x={x}
//         y={y}
//         fill="white"
//         textAnchor="middle"
//         dominantBaseline="central"
//       >
//         {percent > 0 ? `(${(percent * 100).toFixed(2)}%)` : ""}
//       </text>
//     );
//   };
//   const renderBalanceLabel = (props) => {
//     const { cx, cy, midAngle, outerRadius, fill, value, isNegative } = props;

//     const sin = Math.sin(-RADIAN * midAngle);
//     const cos = Math.cos(-RADIAN * midAngle);
//     const sx = cx + (outerRadius + 10) * cos;
//     const sy = cy + (outerRadius + 10) * sin;
//     const mx = cx + (outerRadius + 30) * cos;
//     const my = cy + (outerRadius + 30) * sin;
//     const ex = mx + (cos >= 0 ? 1 : -1) * 10;
//     const ey = my;
//     // const textAnchor = cos >= 0 ? "start" : "end";
//     return (
//       <g>
//         <path
//           d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
//           stroke={fill}
//           fill="none"
//         />
//         <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
//         <text
//           x={ex + (cos >= 0 ? 1 : -1) * 1}
//           y={ey - 5}
//           textAnchor="middle"
//           fill="#333"
//         >
//           {`${isNegative ? value * -1 : value}`}
//         </text>
//       </g>
//     );
//   };

//   return fixMonthlyData().length ? (
//     <ResponsiveContainer>
//       <PieChart>
//         <Pie
//           data={fixMonthlyData()}
//           labelLine={false}
//           label={renderCustomizedLabel}
//           fill="#8884d8"
//           dataKey="value"
//           outerRadius={120}
//         >
//           {fixMonthlyData().map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={COLORS[index]} />
//           ))}
//         </Pie>

//         <Pie
//           data={getBalance()}
//           dataKey="value"
//           innerRadius={130}
//           outerRadius={140}
//           fill={
//             getBalance()[0] && +getBalance()[0].isNegative
//               ? theme.palette.error.dark
//               : theme.palette.success.dark
//           }
//           label={renderBalanceLabel}
//         />
//         <Legend
//           iconSize={15}
//           layout={width < 960 ? "horizontal" : "vertical"}
//           verticalAlign={width < 960 ? "bottom" : "middle"}
//           align={width < 960 ? "center" : "left"}
//           wrapperStyle={{ direction: "ltr" }}
//           formatter={(value, entry, index) => {
//             return (
//               <Typography variant="caption" style={{ margin: "0 3px" }}>
//                 {`${t(`statistics:${entry.payload.text}`)} (${
//                   entry.payload.isNegative
//                     ? entry.payload.value * -1
//                     : entry.payload.value
//                 })`}
//               </Typography>
//             );
//           }}
//         />
//       </PieChart>
//     </ResponsiveContainer>
//   ) : (
//     <Box className="flex__centerlize fullheight">
//       <Typography>{t("statistics:no_statistics_period")}</Typography>
//     </Box>
//   );
// };

export default React.memo(FiscalChart);

import React, { useEffect, useContext, Suspense } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import themeContext from "./contexts/theme/themeContext";
import { Route, Switch, withRouter } from "react-router-dom";
import GlobalSnakebar from "./components/UI/GlobalSnakebar/SnakeBar";
import Loader from "./views/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "./store/actions";
import { authRoutes, publicRoutes } from "./routes";
import { useTranslation } from "react-i18next";

const App = ({ history }) => {
  const { theme } = useContext(themeContext);
  const dispatch = useDispatch();
  const { isAuth, ready, permissions } = useSelector((state) => state.profile);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  if (!ready) {
    return <Loader text={t("common:login")} />;
  }

  const permissionsBasedRoutes = () => {
    return authRoutes.filter((route) =>
      permissions.some((per) => route.permission.includes(per))
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader text={t("common:loading")} />}>
        <Switch>
          {(isAuth ? permissionsBasedRoutes() : publicRoutes).map((route) => (
            <Route
              key={route.path}
              {...route.configs}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </Suspense>
      <GlobalSnakebar />
    </ThemeProvider>
  );
};

export default withRouter(App);

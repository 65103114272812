import * as actionsTypes from "../actions/types";

const initialState = {
  purchases: [],
  repurchases: [],
  suppliers: [],
  searchedSuppliers: [],
  products: [],
  searchedRepurchases: [],
  permissions: {
    suppliers: [],
    purchases: [],
    repurchases: [],
  },
  loading: false,
  itemLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PURCHASES_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_PURCHASES:
      return {
        ...state,
        loading: false,
        purchases: action.purchases,
      };
    case actionsTypes.SET_REPURCHASES:
      return {
        ...state,
        loading: false,
        repurchases: action.repurchases,
      };
    case actionsTypes.SET_SUPPLIERS:
      return {
        ...state,
        loading: false,
        suppliers: action.suppliers,
      };
    case actionsTypes.ALL_PRODUCT_SEARCH:
      return {
        ...state,
        products: action.products,
        loading: false,
      };
    case actionsTypes.ALL_SUPPLIERS_SEARCH:
      return {
        ...state,
        searchedSuppliers: action.searchedSuppliers,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.SET_SUPPLIER_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          suppliers: action.supplierPermissions,
        },
      };
    case actionsTypes.SET_PURCHASE_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          purchases: action.purchasesPermissions,
        },
      };
    case actionsTypes.SET_REPURCHASE_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          repurchases: action.repurchasesPermissions,
        },
      };
    case actionsTypes.PURCHASES_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
        errors: null,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.SET_REPURCHASE_INV_LIST:
      return {
        ...state,
        loading: false,
        searchedRepurchases: action.searchedRepurchases,
      };
    default:
      return state;
  }
};

import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LabelList,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { useTranslation } from "react-i18next";
import ChartSkeleton from "../../../../components/UI/Spinners/ChartSkeleton";
import { useDispatch } from "react-redux";
import {
  getGenericYearStats,
  getGenericMonthlyStats,
} from "../../../../store/actions";
import SelectInput from "../../../../components/UI/Inputs/SelectInput";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";

const BarChart = ({ dataTypes }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState("year");
  const [type, setType] = useState(dataTypes[0].value);
  const [year, setYear] = useState(new Date().getFullYear());
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getGenericStat = useCallback(async () => {
    try {
      setLoading(true);

      if (period === "year") {
        const yearStats = await dispatch(getGenericYearStats(type));
        setData(yearStats);
      } else {
        const monthStats = await dispatch(getGenericMonthlyStats(type, year));
        setData(monthStats);
      }
    } catch (err) {
      //
    } finally {
      setLoading(false);
    }
  }, [dispatch, period, year, type]);

  useEffect(() => {
    getGenericStat();
  }, [getGenericStat, type]);

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SelectInput
            label={t("statistics:data_type")}
            options={dataTypes}
            initialValue={dataTypes[0] ? dataTypes[0].value : ""}
            getResult={(data_type) => setType(data_type)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label={t("statistics:period")}
            options={[
              { value: "year", text: t("common:yearly") },
              { value: "month", text: t("common:monthly") },
            ]}
            initialValue={period}
            getResult={(period) => setPeriod(period)}
          />
        </Grid>
        {period === "month" && (
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={new Date(year, 1, 1)}
                label={t("statistics:choose_year")}
                onChange={(e) => setYear(new Date(e).getFullYear())}
                // style={{ width: "100%" }}
                inputVariant="outlined"
                cancelLabel={t("common:cancel")}
                okLabel={t("common:confirm")}
                format="yyyy"
                InputProps={{
                  endAdornment: <TodayRoundedIcon color="disabled" />,
                }}
                views={["year"]}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
      </Grid>
      <Typography align="center" variant="h4">
        {t(`statistics:${type}_chart_title`)}
      </Typography>
      <div style={{ width: "100%", height: 330 }}>
        {loading ? (
          <ChartSkeleton />
        ) : period === "year" ? (
          <AnnualChart data={data} />
        ) : (
          <MonthlyChart data={data} year={year} />
        )}
      </div>
    </Fragment>
  );
};

const AnnualChart = ({ data }) => {
  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();

  const fixAnnualData = () => {
    if (!data.length) {
      return [
        {
          debit: "0",
          credit: "0",
          amount: "0",
          Year: new Date().getFullYear(),
        },
      ];
    }
    return data;
  };
  return (
    <ResponsiveContainer>
      <ComposedChart
        data={fixAnnualData()}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        style={{ direction: "ltr", fontFamily: "Cairo" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Year" />
        <YAxis />
        <Tooltip />
        <Legend margin={{ top: 10 }} align="center" />
        <ReferenceLine y={0} stroke="#000" />
        <Bar
          dataKey="debit"
          stackId="a"
          fill={theme.palette.primary.dark}
          name={t("common:debit")}
        >
          <LabelList
            position="center"
            dataKey="debit"
            fill={theme.palette.primary.contrastText}
            className={classes.label}
            angle={-90}
          />
        </Bar>
        <Bar
          dataKey="credit"
          stackId="a"
          fill={theme.palette.secondary.light}
          name={t("common:credit")}
        >
          <LabelList
            position="center"
            dataKey="credit"
            fill={theme.palette.secondary.contrastText}
            className={classes.label}
            angle={-90}
          />
        </Bar>
        <Line
          type="monotone"
          dataKey="amount"
          stroke={theme.palette.error.dark}
          strokeWidth={2}
          name={t("reports:amount")}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const MonthlyChart = ({ data, year }) => {
  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();

  const fixDataFor12Months = () => {
    const months = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ];
    const _data = months.map((month) => {
      const hasMonth = data.findIndex((d) => d.month === month);
      if (hasMonth >= 0) {
        return {
          ...data[hasMonth],
          month: t(`statistics:${month}`),
        };
      }
      return {
        debit: "0",
        credit: "0",
        balance: "0",
        month: t(`statistics:${month}`),
        year: year,
      };
    });
    return _data;
  };

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={fixDataFor12Months()}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        style={{ direction: "ltr", fontFamily: "Cairo" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" angle={-45} dx={-10} dy={5} />
        <YAxis />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar
          dataKey="debit"
          stackId="a"
          fill={theme.palette.primary.dark}
          name={t("common:debit")}
        >
          <LabelList
            position="center"
            dataKey="debit"
            fill={theme.palette.primary.contrastText}
            className={classes.label}
            angle={-90}
          />
        </Bar>
        <Bar
          dataKey="credit"
          stackId="a"
          fill={theme.palette.secondary.light}
          name={t("common:credit")}
        >
          <LabelList
            position="center"
            dataKey="credit"
            fill={theme.palette.secondary.contrastText}
            className={classes.label}
            angle={-90}
          />
        </Bar>
        <Line
          type="monotone"
          dataKey="balance"
          stroke={theme.palette.error.dark}
          strokeWidth={2}
          name={t("reports:amount")}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const useStyle = makeStyles((theme) => ({
  label: {
    fontSize: 10,
  },
}));

export default BarChart;

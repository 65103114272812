import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { getUnixTime } from "date-fns";
import { fireSnake } from "./globalsnakebar";
import i18n from "../../i18n";

const loading = () => {
  return {
    type: actionTypes.REPORTS_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return async (dispatch) => {
    // setTimeout(() => {
    //   dispatch({ type: actionTypes.REPORTS_ERRORS, errors: null });
    // }, 6000);
    dispatch({
      type: actionTypes.REPORTS_ERRORS,
      errors: err && err.data ? err.data : err,
    });
  };
};

/*Journals (Daily daftar) */
export const getGeneralJournals = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    // const {
    // delay,
    // } = query;

    query.from_date = getUnixTime(query.from_date) || "";
    query.to_date = getUnixTime(query.to_date) || "";
    query.daily_entry_date = query.daily_entry_date
      ? getUnixTime(query.daily_entry_date)
      : "";
    query.sort = query.orderBy ? query.orderBy.field : "daily_entry_date";
    if (query.orderDirection === "asc") {
      query.sort = "-" + query.sort;
    }
    query["per-page"] = query.perPage || 10;
    delete query.orderBy;
    delete query.orderDirection;
    delete query.perPage;
    delete query.delay;

    let url = `/journal?expand=dailyEntryAccounts`;
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key]) {
        url += `&${key}=${query[key].toString().trim()}`;
      }
    }

    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_JOURNALS, journals: res.data });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/*Sale + Resale + Purchase + Repurchase + Receipt + Payment Reports  + Expense */
export const getSRPREreports = (query, SRPREtype) => {
  return async (dispatch) => {
    dispatch(loading());
    // const {
    // delay,
    // } = query;

    query.from_date = getUnixTime(query.from_date) || "";
    query.to_date = getUnixTime(query.to_date) || "";
    query.invoice_date = query.invoice_date
      ? getUnixTime(query.invoice_date)
      : "";
    query.sort = query.orderBy ? query.orderBy : "-invoice_date";
    if (query.orderDirection === "desc") {
      query.sort = "-" + query.sort;
    }
    query["per-page"] = query.perPage || 10;
    delete query.orderBy;
    delete query.orderDirection;
    delete query.perPage;
    delete query.delay;

    let url = `/${SRPREtype}`;
    let queryOp = 0;
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key]) {
        const operator = queryOp > 0 ? "&" : "?";
        url += `${operator}${key}=${query[key].toString().trim()}`;
        queryOp++;
      }
    }

    try {
      const res = await axios.get(url);
      // dispatch({ type: actionTypes.SET_JOURNALS, journals: res.data });
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/*Product Reports */
export const getProductReports = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    // const {
    // delay,
    // } = query;

    query.from_date = getUnixTime(query.from_date) || "";
    query.to_date = getUnixTime(query.to_date) || "";
    query.invoice_date = query.invoice_date
      ? getUnixTime(query.invoice_date)
      : "";
    query.sort = query.orderBy ? query.orderBy : "-invoice_date";
    if (query.orderDirection === "desc") {
      query.sort = "-" + query.sort;
    }
    query["per-page"] = query.perPage || 10;
    delete query.orderBy;
    delete query.orderDirection;
    delete query.perPage;
    delete query.delay;

    let url = `/dailyentryproduct`;
    let queryOp = 0;
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key]) {
        const operator = queryOp > 0 ? "&" : "?";
        url += `${operator}${key}=${query[key].toString().trim()}`;
        queryOp++;
      }
    }

    try {
      const res = await axios.get(url);
      // dispatch({ type: actionTypes.SET_JOURNALS, journals: res.data });
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/*Supplier Reports */
export const getSupplierReports = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    // const {
    // delay,
    // } = query;

    query.from_date = getUnixTime(query.from_date) || "";
    query.to_date = getUnixTime(query.to_date) || "";
    query.invoice_date = query.invoice_date
      ? getUnixTime(query.invoice_date)
      : "";
    query.sort = query.orderBy ? query.orderBy : "-invoice_date";
    if (query.orderDirection === "desc") {
      query.sort = "-" + query.sort;
    }
    query["per-page"] = query.perPage || 10;
    delete query.orderBy;
    delete query.orderDirection;
    delete query.perPage;
    delete query.delay;

    let url = `/supplierjournal`;
    let queryOp = 0;
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key]) {
        const operator = queryOp > 0 ? "&" : "?";
        url += `${operator}${key}=${query[key].toString().trim()}`;
        queryOp++;
      }
    }

    try {
      const res = await axios.get(url);
      // dispatch({ type: actionTypes.SET_JOURNALS, journals: res.data });
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};
export const searchSupplierInReports = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    const name = query ? query : "";
    let url = `/report/slist?q=${name}`;
    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/* Customer Report */
export const getCustomerReports = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    // const {
    // delay,
    // } = query;

    query.from_date = getUnixTime(query.from_date) || "";
    query.to_date = getUnixTime(query.to_date) || "";
    query.invoice_date = query.invoice_date
      ? getUnixTime(query.invoice_date)
      : "";
    query.sort = query.orderBy ? query.orderBy : "-invoice_date";
    if (query.orderDirection === "desc") {
      query.sort = "-" + query.sort;
    }
    query["per-page"] = query.perPage || 10;
    delete query.orderBy;
    delete query.orderDirection;
    delete query.perPage;
    delete query.delay;

    let url = `/customerjournal`;
    let queryOp = 0;
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key]) {
        const operator = queryOp > 0 ? "&" : "?";
        url += `${operator}${key}=${query[key].toString().trim()}`;
        queryOp++;
      }
    }

    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};
export const searchCustomerInReports = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    const name = query ? query : "";
    let url = `/report/clist?q=${name}`;
    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/* Employee Report */
export const getEmployeeReports = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    // const {
    // delay,
    // } = query;

    query.from_date = getUnixTime(query.from_date) || "";
    query.to_date = getUnixTime(query.to_date) || "";
    query.invoice_date = query.invoice_date
      ? getUnixTime(query.invoice_date)
      : "";
    query.sort = query.orderBy ? query.orderBy : "-invoice_date";
    if (query.orderDirection === "desc") {
      query.sort = "-" + query.sort;
    }
    query["per-page"] = query.perPage || 10;
    delete query.orderBy;
    delete query.orderDirection;
    delete query.perPage;
    delete query.delay;

    let url = `/employeejournal`;
    let queryOp = 0;
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key]) {
        const operator = queryOp > 0 ? "&" : "?";
        url += `${operator}${key}=${query[key].toString().trim()}`;
        queryOp++;
      }
    }

    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

export const searchEmployeeInReports = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    const name = query ? query : "";
    let url = `/report/elist?q=${name}`;
    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/* Account Report */
export const getAccountReports = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    // const {
    // delay,
    // } = query;

    query.from_date = getUnixTime(query.from_date) || "";
    query.to_date = getUnixTime(query.to_date) || "";
    query.invoice_date = query.invoice_date
      ? getUnixTime(query.invoice_date)
      : "";
    query.sort = query.orderBy ? query.orderBy : "-invoice_date";
    if (query.orderDirection === "desc") {
      query.sort = "-" + query.sort;
    }
    query["per-page"] = query.perPage || 10;
    delete query.orderBy;
    delete query.orderDirection;
    delete query.perPage;
    delete query.delay;

    let url = `/accountjournal`;
    let queryOp = 0;
    for (const key in query) {
      if (query.hasOwnProperty(key) && query[key]) {
        const operator = queryOp > 0 ? "&" : "?";
        url += `${operator}${key}=${query[key].toString().trim()}`;
        queryOp++;
      }
    }

    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

export const searchAccountInReports = () => {
  return async (dispatch) => {
    dispatch(itemLoading());
    let url = `/report/aclist?q=`;
    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/*Account tree */
export const getAccountsTree = () => {
  return async (dispatch) => {
    dispatch(loading());
    let url = `/account`;
    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_ACC_TREE, tree: res.data });
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/* Taxations */
export const getTaxations = () => {
  return async (dispatch) => {
    dispatch(loading());
    let url = `/taxation`;
    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};
export const postTaxation = (year, quarter) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    let url = `/taxation`;
    try {
      const res = await axios.post(url, { year, quarter });

      if (!res.data.success) {
        let _text = i18n.t("common:uncommonerror");
        const error = {
          message: res.data.errors || _text,
        };
        throw error;
      }
      return res.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
      // dispatch(setErrors(error));
      // dispatch(GeneralFiresnake(error));
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getTaxationView = (year, quarter) => {
  return async (dispatch) => {
    dispatch(loading());
    let url = `/taxation/view/${year}/${quarter}`;
    try {
      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/**
 * User Logs
 */
export const getUserLogs = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let sort = query.orderBy ? query.orderBy.field : "date";
      if (query.orderDirection === "desc") {
        sort = "-" + sort;
      }
      let page = query.page + 1;
      let perPage = query.perPage || 10;

      let url = `/report?page=${page}&per-page=${perPage}&sort=${sort}`;

      const res = await axios.get(url);
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
    }
  };
};

/*
 * Reports Permissions
 */
export const getReportsPermissions = () => {
  return async (dispatch, getState) => {
    const { reports } = getState();
    if (reports.permissions.length) {
      return;
    }
    dispatch(loading());
    try {
      const res = await axios.get("/report/permissions");
      dispatch({
        type: actionTypes.SET_REPORTS_PERMISSIONS,
        reportPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveReportsPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/report/permissions", permissions);
      dispatch({
        type: actionTypes.SET_REPORTS_PERMISSIONS,
        reportPermissions: permissions,
      });
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
//Helpers
const GeneralFiresnake = (error) => {
  const err = error && error.data ? error.data : error;
  return (dispatch) => {
    let message = err.message ? err.message : "";
    if (err.status === 401 || err.status === 403) {
      return dispatch(
        fireSnake("error", message || i18n.t("common:unaothurized"))
      );
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", message || "خطأ كود 500"));
    }
    dispatch(fireSnake("error", message || i18n.t("common:uncommonerror")));
  };
};

import * as actionTypes from "../actions/types";

const initialState = {
  offers: [],
  searchedCustomers: [],
  products: [],
  permissions: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OFFERS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
        errors: null,
      };
    case actionTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionTypes.SET_OFFERS:
      return {
        ...state,
        loading: false,
        offers: action.offers,
      };
    case actionTypes.OFFERS_CUSTOMERS_SEARCH:
      return {
        ...state,
        searchedCustomers: action.customers,
        itemLoading: false,
      };
    case actionTypes.OFFERS_PRODUCT_SEARCH:
      const productsFixedNumbers = action.products.map((prod) => {
        return {
          ...prod,
          price: parseFloat(prod.price).toFixed(2),
          min_price: parseFloat(prod.min_price).toFixed(2),
          jprice: parseFloat(prod.jprice).toFixed(2),
          min_jprice: parseFloat(prod.min_jprice).toFixed(2),
          res_price: parseFloat(prod.res_price).toFixed(2),
          lastprice: parseFloat(prod.lastprice).toFixed(2),
          jlastprice: parseFloat(prod.jlastprice).toFixed(2),
          quantity: parseInt(prod.quantity).toFixed(2),
          tax_value: parseFloat(prod.tax_value ? prod.tax_value : 0).toFixed(2),
        };
      });

      return {
        ...state,
        products: productsFixedNumbers,
        itemLoading: false,
      };
    case actionTypes.OFFERS_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };

    case actionTypes.SET_OFFERS_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: action.offersPermisssoins,
      };
    default:
      return state;
  }
};

import React from "react";
import Home from "./views/Home/Home";
import Login from "./views/Auth/Login";
import Page404 from "./views/404";

const Purchases = React.lazy(() => import("./views/Purchases/Purchases"));
const Products = React.lazy(() => import("./views/Products/Products"));
const Sales = React.lazy(() => import("./views/Sales/Sales"));
const Stores = React.lazy(() => import("./views/Stores/Stores"));
const Expenses = React.lazy(() => import("./views/Expenses/Expenses"));
const Receipts = React.lazy(() => import("./views/Vouchers/Receipts"));
const Accounts = React.lazy(() => import("./views/Accounts/Accounts"));
const AccountTransfers = React.lazy(() =>
  import("./views/Accounts/AccountTransfer")
);
const ClosureReports = React.lazy(() =>
  import("./views/Accounts/SalesClosure")
);
const OffersList = React.lazy(() => import("./views/Offers/Offers"));

/* Reports */
const Reports = React.lazy(() => import("./views/Reports/Reports"));
const GeneralJournal = React.lazy(() =>
  import("./views/Reports/GeneralJournal/JournalList")
);
const DailyJournal = React.lazy(() =>
  import("./views/Reports/DailyJournal/DailyJournal")
);
const SRPRreports = React.lazy(() =>
  import("./views/Reports/SRPRreports/SRPRreports")
);
const RPEreports = React.lazy(() =>
  import("./views/Reports/RPEreports/RPEreports")
);
const ProductReports = React.lazy(() =>
  import("./views/Reports/ProductReports/ProductReports")
);
const SupplierReports = React.lazy(() =>
  import("./views/Reports/SupplierReports/SupplierReports")
);
const CustomerReports = React.lazy(() =>
  import("./views/Reports/CustomerReports/CustomerReports")
);
const EmployeeReports = React.lazy(() =>
  import("./views/Reports/EmployeeReports/EmployeeReports")
);
const AccountReports = React.lazy(() =>
  import("./views/Reports/AccountReports/AccountReports")
);
const TransferReports = React.lazy(() =>
  import("./views/Reports/TransferReports/TransferReports")
);
const AccountsTree = React.lazy(() =>
  import("./views/Reports/AccountsTree/AccountsTree")
);
const Taxations = React.lazy(() =>
  import("./views/Reports/Taxations/Taxations")
);
const TaxationView = React.lazy(() =>
  import("./views/Reports/Taxations/TaxationView")
);
const UserLogs = React.lazy(() => import("./views/Reports/UserLogs/UserLogs"));
/*Settings */
const Settings = React.lazy(() => import("./views/Settings/Settings"));
const General = React.lazy(() => import("./views/Settings/General/General"));
const Banks = React.lazy(() => import("./views/Settings/Banks/Banks"));
const Users = React.lazy(() => import("./views/Settings/Users/Users"));
const Employees = React.lazy(() =>
  import("./views/Settings/Employees/Employees")
);
const Permissions = React.lazy(() =>
  import("./views/Settings/Permissions/PermissionsList")
);

export const authRoutes = [
  {
    path: "/",
    component: Home,
    configs: { exact: true },
    permission: ["sitelogin"],
  },
  {
    path: "/stores",
    component: Stores,
    permission: ["managestore"],
  },
  {
    path: "/sales",
    component: Sales,
    permission: ["managesales"],
  },
  {
    path: "/purchases",
    component: Purchases,
    permission: ["managepurchases"],
  },
  {
    path: "/products",
    component: Products,
    permission: ["manageproduct"],
  },
  {
    path: "/receipts",
    component: Receipts,
    permission: ["managereceipt"],
  },
  {
    path: "/expenses",
    component: Expenses,
    permission: ["manageexpense"],
  },
  {
    path: "/accounts",
    component: Accounts,
    configs: { exact: true },
    permission: ["manageaccount"],
  },
  {
    path: "/accounts/transfer",
    component: AccountTransfers,
    configs: { exact: true },
    permission: ["managemoneytransfer"],
  },
  {
    path: "/accounts/saleclosure",
    component: ClosureReports,
    permission: ["showsalesfund"],
    configs: { exact: true },
  },
  {
    path: "/reports",
    component: Reports,
    configs: { exact: true },
    permission: ["managereport"],
  },
  {
    path: "/reports/journals",
    component: GeneralJournal,
    permission: ["showjournalreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/daily",
    component: DailyJournal,
    permission: ["showjournalreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/sales",
    component: SRPRreports,
    permission: ["showsalesreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/resales",
    component: SRPRreports,
    permission: ["showresalesreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/purchases",
    component: SRPRreports,
    permission: "showpurchasesreport",
    configs: { exact: true },
  },
  {
    path: "/reports/repurchases",
    component: SRPRreports,
    permission: ["showrepurchasesreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/expense",
    component: RPEreports,
    permission: ["showexpensereport"],
    configs: { exact: true },
  },
  {
    path: "/reports/receipts",
    component: RPEreports,
    permission: ["showreceiptreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/payments",
    component: RPEreports,
    permission: ["showpaymentreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/products",
    component: ProductReports,
    permission: ["showproductreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/suppliers",
    component: SupplierReports,
    permission: ["showsupplierreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/customers",
    component: CustomerReports,
    permission: ["showcustomerreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/employees",
    component: EmployeeReports,
    permission: ["showemployeereport"],
    configs: { exact: true },
  },
  {
    path: "/reports/accounts",
    component: AccountReports,
    permission: ["showaccountreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/moneytransfer",
    component: TransferReports,
    permission: ["showmoneytransferreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/tree",
    component: AccountsTree,
    permission: ["showaccountreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/taxations",
    component: Taxations,
    configs: { exact: true },
    permission: ["showtaxationreport"],
  },
  {
    path: "/reports/taxations/:year/:quarter/:status",
    component: TaxationView,
    permission: ["showtaxationreport"],
    configs: { exact: true },
  },
  {
    path: "/reports/logs",
    component: UserLogs,
    permission: ["showjournalreport"],
    configs: { exact: true },
  },
  {
    path: "/offers",
    component: OffersList,
    permission: ["managepricelist"],
    configs: { exact: true },
  },
  {
    path: "/settings",
    component: Settings,
    configs: { exact: true },
    permission: ["settings"],
  },
  {
    path: "/settings/general",
    component: General,
    permission: ["settings"],
    configs: { exact: true },
  },
  {
    path: "/settings/banks",
    component: Banks,
    permission: ["managebank"],
    configs: { exact: true },
  },
  {
    path: "/settings/users",
    component: Users,
    permission: ["manageuser"],
  },
  {
    path: "/settings/employees",
    component: Employees,
    permission: ["manageemployee"],
    configs: { exact: true },
  },
  {
    path: "/settings/permissions",
    component: Permissions,
    permission: ["permissions"],
    configs: { exact: true },
  },
  {
    path: "*",
    component: Page404,
    permission: ["sitelogin"],
  },
];
export const publicRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "*",
    component: Page404,
  },
];

import * as actionTypes from "../actions/types";

const initialState = {
  stores: [],
  transfers: [],
  permissions: {
    stores: [],
    transfers: [],
  },
  loading: false,
  itemLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORES_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
        errors: null,
      };
    case actionTypes.SET_STORES:
      return {
        ...state,
        stores: action.stores,
        loading: false,
      };
    case actionTypes.SET_TRANSFERS:
      return {
        ...state,
        stores: action.transfers,
        loading: false,
      };
    case actionTypes.SET_STORE_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          stores: action.storePermissions,
        },
      };
    case actionTypes.SET_TRANSFERS_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          transfers: action.transfersPermissions,
        },
      };
    case actionTypes.STORE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};

import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { fireSnake } from "./globalsnakebar";
import i18n from "../../i18n";

const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};

const loading = () => {
  return {
    type: actionTypes.EXPENSES_LOADING,
  };
};
const setErrors = (err) => {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch({ type: actionTypes.EXPENSES_ERRORS, errors: null });
    }, 6000);
    dispatch({
      type: actionTypes.EXPENSES_ERRORS,
      errors: err && err.data ? err.data : err,
    });
  };
};

/*Expenses */
export const getExpense = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/expense/${id}`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getExpenses = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "expense_date";
    let orderDirection = "-";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "expense_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/expense?per-page=${query.pageSize}&page=${query.page + 1}&sort=${
          orderDirection + orderQuery
        }`
      );
      dispatch({
        type: actionTypes.SET_EXPENSE,
        expenses: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const addEditExpense = (expense, editId) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      console.log(expense);
      if (editId) {
        await axios.put(`/expense/${editId}`, expense);
      } else {
        await axios.post("/expense", expense);
      }
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteExpense = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const result = await axios.delete("expense/" + id);
      if (!result.data.success) {
        let msg = "حدث خطأ ما";
        if (result.data.errors) {
          msg = result.data.errors;
        }
        /*I can't believe I need to do this my self!! */
        const error = { message: msg, status: 401 };
        throw error;
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
let timeout;
export const searchClist = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(`/expense/clist?q=${query ? query : ""}`);
        dispatch({
          type: actionTypes.SET_CLIST,
          clist: res.data,
        });
      } catch (err) {
        const error = err.response ? err.response.data : err;
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(error));
        throw error;
      }
    }, 1500);
  };
};
export const searchElist = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(`/expense/elist?q=${query ? query : ""}`);
        dispatch({
          type: actionTypes.SET_ELIST,
          elist: res.data,
        });
      } catch (err) {
        const error = err.response ? err.response.data : err;
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(error));
        throw error;
      }
    }, 1500);
  };
};
export const getExpensesPermissions = () => {
  return async (dispatch, getState) => {
    const { expenses } = getState();
    if (expenses.permissions.expenses.length) {
      return;
    }
    dispatch(loading());
    try {
      const res = await axios.get("/expense/permissions");
      dispatch({
        type: actionTypes.SET_EXPENSES_PERMISSIONS,
        expensePermissions: res.data,
      });
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveExpensesPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/expense/permissions", permissions);
      dispatch({
        type: actionTypes.SET_EXPENSES_PERMISSIONS,
        expensePermissions: permissions,
      });
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

/*Groups */
export const getExpensesGroups = (query) => {
  return async (dispatch, getState) => {
    dispatch(loading());
    let orderQuery = "id";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "id";
      orderDirection = query.orderDirection === "asc" ? "-" : "";
    }
    try {
      const res = await axios.get(
        `/expensegroup?expand=expensesCount&sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_EXPENSE_GROUPS, groups: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addEditExpenseGroup = (group, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/expensegroup/" + group.id, { ...group });
      } else {
        await axios.post("/expensegroup", { ...group });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteExpenseGroup = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const result = await axios.delete("/expensegroup/" + id);
      if (!result.data.success) {
        let msg = "حدث خطأ ما";
        if (result.data.errors) {
          msg = result.data.errors;
        }
        /*I can't believe I need to do this my self!! */
        const error = { message: msg, status: 500 };
        throw error;
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getExpensesGroupPermissions = () => {
  return async (dispatch, getState) => {
    const { expenses } = getState();
    if (expenses.permissions.expenses.length) {
      return;
    }
    dispatch(loading());
    try {
      const res = await axios.get("/expensegroup/permissions");
      dispatch({
        type: actionTypes.SET_EXPENSES_GROUP_PERMISSIONS,
        groupPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveExpensesGroupPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/expensegroup/permissions", permissions);
      dispatch({
        type: actionTypes.SET_EXPENSES_GROUP_PERMISSIONS,
        groupPermissions: permissions,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

//Helpers
const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", i18n.t("common:uncommonerror")));
  };
};

import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { fireSnake } from "./globalsnakebar";
import i18n from "../../i18n";

const loading = () => {
  return {
    type: actionTypes.VOUCHERS_LOADING,
  };
};
const setErrors = (err) => {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch({ type: actionTypes.VOUCHERS_ERRORS, errors: null });
    }, 6000);
    dispatch({
      type: actionTypes.VOUCHERS_ERRORS,
      errors: err && err.data ? err.data : err,
    });
  };
};

/*Receipts Vouchers */
export const getReceipt = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/receipt/${id}?expand=receiver`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getReceipts = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "receipt_date";
    let orderDirection = "-";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "receipt_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/receipt?expand=receiver&per-page=${query.pageSize}&page=${
          query.page + 1
        }&sort=${orderDirection + orderQuery}`
      );
      dispatch({
        type: actionTypes.SET_RECEIPTS,
        receipts: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const addEditReceipt = (receipt, editId) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (editId) {
        await axios.put(`/receipt/${editId}`, receipt);
      } else {
        await axios.post("/receipt", receipt);
      }
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteReceipt = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const result = await axios.delete("receipt/" + id);
      if (!result.data.success) {
        let msg = "حدث خطأ ما";
        if (result.data.errors) {
          msg = result.data.errors;
        }
        /*I can't believe I need to do this my self!! */
        const error = { message: msg, status: 401 };
        throw error;
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
let timeout;
export const searchReceiptReceivers = (query, type = "customer") => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(
          `/receipt/rlist?receipt_type=${type}&q=${query ? query : ""}`
        );
        dispatch({
          type: actionTypes.SET_RECEIPT_RECEIVERS,
          receiptReceivers: res.data,
        });
      } catch (err) {
        const error = err.response ? err.response.data : err;
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(error));
        throw error;
      }
    }, 1500);
  };
};
export const clearReceiptReceivers = () => {
  return {
    type: actionTypes.SET_RECEIPT_RECEIVERS,
    receiptReceivers: [],
  };
};
export const getReceiptsPermissions = () => {
  return async (dispatch, getState) => {
    const { vouchers } = getState();
    if (vouchers.permissions.receipts.length) {
      return;
    }
    dispatch(loading());
    try {
      const res = await axios.get("/receipt/permissions");
      dispatch({
        type: actionTypes.SET_RECEIPTS_PERMISSIONS,
        receiptsPermissions: res.data,
      });
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveReceiptsPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/receipt/permissions", permissions);
      dispatch({
        type: actionTypes.SET_RECEIPTS_PERMISSIONS,
        receiptsPermissions: permissions,
      });
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

/*Payment Vouchers */
export const getPeymant = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/payment/${id}?expand=receiver`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getPeymants = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "payment_date";
    let orderDirection = "-";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "payment_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/payment?expand=receiver&per-page=${query.pageSize}&page=${
          query.page + 1
        }&sort=${orderDirection + orderQuery}`
      );
      dispatch({
        type: actionTypes.SET_PAYMENTS,
        receipts: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const addEditPayment = (payment, editId) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (editId) {
        await axios.put(`/payment/${editId}`, payment);
      } else {
        await axios.post("/payment", payment);
      }
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deletePayment = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const result = await axios.delete("payment/" + id);
      if (!result.data.success) {
        let msg = "حدث خطأ ما";
        if (result.data.errors) {
          msg = result.data.errors;
        }
        /*I can't believe I need to do this my self!! */
        const error = { message: msg, status: 401 };
        throw error;
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
let timeoutp;
export const searchPaymentReceivers = (query, type = "customer") => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeoutp);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(
          `/payment/rlist?payment_type=${type}&q=${query ? query : ""}`
        );
        dispatch({
          type: actionTypes.SET_PAYMENT_RECEIVERS,
          paymentReceivers: res.data,
        });
      } catch (err) {
        const error = err.response ? err.response.data : err;
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(error));
        throw error;
      }
    }, 1500);
  };
};
export const clearPaymentReceivers = () => {
  return {
    type: actionTypes.SET_PAYMENT_RECEIVERS,
    paymentReceivers: [],
  };
};
export const getPaymentsPermissions = () => {
  return async (dispatch, getState) => {
    const { vouchers } = getState();
    if (vouchers.permissions.receipts.length) {
      return;
    }
    dispatch(loading());
    try {
      const res = await axios.get("/payment/permissions");
      dispatch({
        type: actionTypes.SET_PAYMENTS_PERMISSIONS,
        paymentsPermissions: res.data,
      });
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const savePaymentsPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/payment/permissions", permissions);
      dispatch({
        type: actionTypes.SET_PAYMENTS_PERMISSIONS,
        paymentsPermissions: permissions,
      });
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

//Helpers
const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", i18n.t("common:uncommonerror")));
  };
};

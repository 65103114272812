import React, { useContext } from "react";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import SwapHorizOutlinedIcon from "@material-ui/icons/SwapHorizOutlined";
import HorizontalSplitIcon from "@material-ui/icons/HorizontalSplit";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import {
  Paper,
  Typography,
  Grid,
  Box,
  makeStyles,
  Slide,
  Zoom,
} from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import themeContext from "../../../contexts/theme/themeContext";
import { useTranslation } from "react-i18next";

const LoginSide = () => {
  const { gradient_primary, gradient_third, language } = useContext(
    themeContext
  );
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid item xs={false} sm={6} md={8} lg={9} className={classes.image}>
      <div className="flex ver fullheight flex-justify-evenly px-2">
        <Slide
          direction={language === "ar-AR" ? "right" : "left"}
          timeout={500}
          in
        >
          <Box component="div" className={classes.top}>
            <Typography
              variant="h3"
              color="primary"
              className="py text-shadow-grey bolder"
            >
              {t("common:cloudaccounting")}
            </Typography>
            <Typography variant="h4" className="py text-error text-shadow-grey">
              {t("common:cloudaccoutingsubtitle")}
            </Typography>
            <Tickitem text={t("common:tick1")} />
            <Tickitem text={t("common:tick2")} />
            <Tickitem text={t("common:tick3")} />
            <Tickitem text={t("common:tick4")} />
          </Box>
        </Slide>
        <Box marginTop={2}>
          <Grid container spacing={3}>
            <SquareItem
              timeout={200}
              bg={gradient_primary}
              icon={
                <StoreOutlinedIcon fontSize="large" className="text-light" />
              }
              text="يدعم متابعة عدة فروع في آن واحد"
            />
            <SquareItem
              timeout={800}
              bg={gradient_third}
              icon={<CloudDoneIcon fontSize="large" className="text-light" />}
              text="بيانات سحابية آمنة تصلك أينما كنت"
            />
            <SquareItem
              timeout={400}
              bg={gradient_primary}
              icon={
                <SwapHorizOutlinedIcon
                  fontSize="large"
                  className="text-light"
                />
              }
              text="إدارة مخزون تدعم التحويلات بين فروع"
            />
            <SquareItem
              timeout={600}
              bg={gradient_third}
              icon={
                <HorizontalSplitIcon
                  fontSize="large"
                  className="text-light"
                  style={{ transform: "rotate(90deg)" }}
                />
              }
              text="يدعم نظام الباركود وإدارة نقاط البيع"
            />
          </Grid>
        </Box>
      </div>
    </Grid>
  );
};

const SquareItem = ({ timeout, bg, text, icon }) => {
  return (
    <Zoom in timeout={timeout}>
      <Grid md={3} sm={6} item>
        <Paper elevation={6} className={`p-1 center ${bg} hoverable-top`}>
          {icon}
          <Typography className="text-light">{text}</Typography>
        </Paper>
      </Grid>
    </Zoom>
  );
};
const Tickitem = ({ text }) => {
  return (
    <Typography
      color="primary"
      className="flex align-items-center bold text-shadow-grey"
      style={{ marginBottom: 7 }}
    >
      <CheckCircleOutlinedIcon
        color="primary"
        className="flex text-shadow-grey"
      />
      {text}
    </Typography>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url('./assets/images/bg-login.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[100]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  top: {
    background: "#ffffff77",
    padding: 10,
    borderRadius: 5,
  },
}));
export default LoginSide;

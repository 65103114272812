import * as actionsTypes from "../actions/types";
import Globals from "../../util/global";

const initialState = {
  user_id: 0,
  user_name: "",
  stores: [],
  roles: [],
  caisse: 0,
  sales: 0,
  customers: 0,
  pricelist: 0,
  permissions: [],
  isAuth: false,
  ready: false,
  loading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        ready: false,
      };
    case actionsTypes.GET_PROFILE:
      console.log(action);
      //Stores are used outside of components, so save in global config
      Globals.stores = action.stores;
      Globals.user_id = action.user_id;
      return {
        ...state,
        user_id: action.user_id,
        user_name: action.user_name,
        stores: action.stores,
        roles: action.roles,
        caisse: action.caisse,
        sales: action.sales,
        customers: action.customers,
        pricelist: action.pricelist,
        permissions: action.permissions,
        loading: false,
        ready: true,
        isAuth: true,
      };
    case actionsTypes.PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        isAuth: false,
        errors: action.errors,
        ready: true,
      };
    case actionsTypes.LOGOUT:
      return { ...initialState, ready: true };
    default:
      return state;
  }
};

import React, { useCallback, useEffect, useState } from "react";
import { Box, Container } from "@material-ui/core";
import MainMenu from "./components/MainMenu/MainMenu";
import TopCard from "./components/Cards/TopCards";
import Layout from "../../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { refreshProfileSilently } from "../../store/actions/profile";
import StatisticsCharts from "./components/Statistics/StatisticsCharts";
import { useTranslation } from "react-i18next";
import SwitchButtons from "../../components/UI/Buttons/SwitchButtons/SwitchButtons";
import { ifHasPermission } from "../../util/generals";
// import Loader from "../Loader";

const Home = () => {
  // const classes = useStyle();
  const permissions = useSelector((state) => state.profile.permissions);
  const initialGroup = ifHasPermission(permissions, [
    "showsalesreport",
    "showresalesreport",
    "showpurchasesreport",
    "showrepurchasesreport",
    "showexpensereport",
    "showpaymentreport",
    "showreceiptreport",
    "manageaccountreport",
    "showaccountreport",
  ])
    ? "charts"
    : "menu";
  const [active, setActive] = useState(initialGroup);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const refreshProfileInfo = useCallback(async () => {
    dispatch(refreshProfileSilently());
  }, [dispatch]);

  useEffect(() => {
    refreshProfileInfo();
  }, [refreshProfileInfo]);

  const buttons = [
    {
      name: "charts",
      content: t("statistics:charts"),
      permission: [
        "showsalesreport",
        "showresalesreport",
        "showpurchasesreport",
        "showrepurchasesreport",
        "showexpensereport",
        "showpaymentreport",
        "showreceiptreport",
        "manageaccountreport",
        "showaccountreport",
      ],
    },
    {
      name: "menu",
      content: t("common:menu"),
      permission: ["sitelogin"],
    },
  ];

  const permissionedButtons = buttons.filter((btn) =>
    permissions.some((per) => btn.permission.includes(per))
  );

  let content = <StatisticsCharts />;
  if (active === "menu") {
    content = <MainMenu />;
  }
  return (
    <Layout>
      <Container>
        <TopCard />
        <div className="py center">
          <SwitchButtons
            buttons={permissionedButtons}
            state={active}
            onClick={setActive}
            // fullWidth
          />
        </div>
        <Box height={15} />
        {content}
      </Container>
    </Layout>
  );
};

// const useStyle = makeStyles({
// root: {
//   height: "100vh",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   background: "#bbb"
// }
// });

export default Home;

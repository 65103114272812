import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.PRODUCTS_LOADING,
  };
};
const setErrors = (err) => {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch({ type: actionTypes.PRODUCTS_ERRORS, errors: null });
    }, 6000);
    dispatch({
      type: actionTypes.PRODUCTS_ERRORS,
      errors: err && err.data ? err.data : err,
    });
  };
};

/*Products */
export const getProducts = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    const orderDirection = query.orderDirection === "asc" ? "-" : "";
    try {
      const res = await axios.get(
        `/product?expand=productCollections,productStores,productGroup,manufacturer,tax,unit,products&per-page=${
          query.pageSize
        }&page=${query.page + 1}&sort=${
          orderDirection + productsOrderQuery(query)
        }`
      );
      dispatch({ type: actionTypes.SET_PRODUCTS, products: res.data.product });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addEditProduct = (product, editId) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (editId) {
        await axios.put("/product/" + editId, product);
      } else {
        await axios.post("/product", product);
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteProduct = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/product/" + id);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteProductCollection = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/productcollection/" + id);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getProductPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get("/product/permissions");
      dispatch({
        type: actionTypes.SET_PRODUCTS_PERMISSIONS,
        productPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveProductPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/product/permissions", permissions);
      dispatch({
        type: actionTypes.SET_PRODUCTS_PERMISSIONS,
        productPermissions: permissions,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

/*Units */
export const getProductUnits = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "id";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "id";
      orderDirection = query.orderDirection === "asc" ? "-" : "";
    }
    try {
      const res = await axios.get(`/unit?sort=${orderDirection + orderQuery}`);
      dispatch({ type: actionTypes.SET_UNITS, units: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addEditUnit = (unit, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/unit/" + unit.id, { ...unit });
      } else {
        await axios.post("/unit", { ...unit });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteUnit = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/unit/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getUnitPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get("/unit/permissions");
      dispatch({
        type: actionTypes.SET_UNIT_PERMISSIONS,
        unitPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const saveUnitPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/unit/permissions", permissions);
      dispatch({
        type: actionTypes.SET_UNIT_PERMISSIONS,
        unitPermissions: permissions,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
/*Groups */
export const getProductGroups = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "created_at";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "created_at";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/productgroup/tree?expand=products&sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_GROUPS, groups: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addEditGroup = (group, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/productgroup/" + group.id, { ...group });
      } else {
        await axios.post("/productgroup", { ...group });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteGroup = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/productgroup/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getProductGroupPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get("/productgroup/permissions");
      dispatch({
        type: actionTypes.SET_PRODUCT_GROUP_PERMISSIONS,
        groupPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveProductGroupPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/productgroup/permissions", permissions);
      dispatch({
        type: actionTypes.SET_PRODUCT_GROUP_PERMISSIONS,
        groupPermissions: permissions,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

/*Manufacturers */
export const getProductManufacturer = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "created_at";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "created_at";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/manufacturer?sort=${orderDirection + orderQuery}`
      );
      dispatch({
        type: actionTypes.SET_MANUFACTURERS,
        manufacturers: res.data,
      });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const addEditManufacturer = (manufacturer, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/manufacturer/" + manufacturer.id, {
          ...manufacturer,
        });
      } else {
        await axios.post("/manufacturer", { ...manufacturer });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteManufacturer = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/manufacturer/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

/*Taxs */
export const getTaxs = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "created_at";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "created_at";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(`/tax?sort=${orderDirection + orderQuery}`);
      dispatch({ type: actionTypes.SET_TAXS, taxs: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
    }
  };
};

export const addEditTax = (tax, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/tax/" + tax.id, { ...tax });
        dispatch({ type: actionTypes.UNLOAD });
      } else {
        await axios.post("/tax", { ...tax });
        // dispatch({ type: actionTypes.UNLOAD });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteTax = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/tax/" + id);
      // dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const getTaxPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get("/tax/permissions");
      dispatch({
        type: actionTypes.SET_TAX_PERMISSIONS,
        taxPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveTaxPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.post("/tax/permissions", permissions);
      dispatch({
        type: actionTypes.SET_TAX_PERMISSIONS,
        taxPermissions: permissions,
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
//Helpers
const productsOrderQuery = (query) => {
  if (query.orderBy && query.orderBy.field === "productGroup.name") {
    return "product_group_id";
  }
  if (query.orderBy) {
    return query.orderBy.field;
  }
  return "id";
};

import * as actionsTypes from "../actions/types";

const initialState = {
  products: [],
  productGroups: [],
  productsUnits: [],
  productsManufacturers: [],
  productTaxs: [],
  permissions: {
    units: [],
    taxs: [],
    productGroups: [],
    products: [],
  },
  loading: false,
  itemLoading: false,
  errors: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_PRODUCTS:
      return {
        ...state,
        loading: false,
        products: action.products,
      };
    case actionsTypes.SET_PRODUCTS_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          products: action.productPermissions,
        },
      };
    case actionsTypes.SET_GROUPS:
      return {
        ...state,
        loading: false,
        productGroups: action.groups,
      };
    case actionsTypes.SET_PRODUCT_GROUP_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          productGroups: action.groupPermissions,
        },
      };
    case actionsTypes.SET_UNITS:
      return {
        ...state,
        loading: false,
        productsUnits: action.units,
      };
    case actionsTypes.SET_UNIT_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          units: action.unitPermissions,
        },
      };

    case actionsTypes.SET_MANUFACTURERS:
      return {
        ...state,
        loading: false,
        productsManufacturers: action.manufacturers,
      };
    case actionsTypes.SET_TAXS:
      return {
        ...state,
        productTaxs: action.taxs,
        loading: false,
      };
    case actionsTypes.SET_TAX_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          taxs: action.taxPermissions,
        },
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.PRODUCTS_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};

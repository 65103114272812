import React from "react";
// import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import LocalGroceryStoreOutlinedIcon from "@material-ui/icons/LocalGroceryStoreOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import WidgetsRoundedIcon from "@material-ui/icons/WidgetsRounded";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import BookmarkBorderRoundedIcon from "@material-ui/icons/BookmarkBorderRounded";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";

export const data = [
  // {
  //   id: 1,
  //   title: "لوحة التحكم",
  //   icon: <PollOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />,
  //   link: "/dashboard",
  // },
  {
    id: 2,
    title: "inventories",
    icon: <StoreOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/stores",
    permission: ["managestore"],
  },
  {
    id: 3,
    title: "purchases",
    icon: <LocalOfferOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/purchases",
    permission: ["managepurchases"],
  },
  {
    id: 4,
    title: "products",
    icon: <WidgetsRoundedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/products",
    permission: ["manageproduct"],
  },
  {
    id: 5,
    title: "sales",
    icon: (
      <LocalGroceryStoreOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />
    ),
    link: "/sales",
    permission: ["managesales"],
  },
  {
    id: 6,
    title: "expenses",
    icon: (
      <MonetizationOnOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />
    ),
    link: "/expenses",
    permission: ["manageexpense"],
  },
  {
    id: 7,
    title: "receipts",
    icon: <ReceiptOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/receipts",
    permission: ["managereceipt"],
  },
  {
    id: 8,
    title: "accounts",
    icon: <DescriptionRoundedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/accounts",
    permission: ["manageaccount"],
  },
  {
    id: 9,
    title: "settings",
    icon: <SettingsOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/settings",
    permission: ["settings"],
  },
  {
    id: 10,
    title: "reports",
    icon: <TimelineOutlinedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/reports",
    permission: ["managereport"],
  },
  {
    id: 11,
    title: "offers",
    icon: <BookmarkBorderRoundedIcon style={{ fontSize: 50, color: "#eee" }} />,
    link: "/offers",
    permission: ["managepricelist"],
  },
];

import Globals from "./global";
import i18n from "../i18n";

export const pickLangVal = (language, item, selector) => {
  switch (selector) {
    case "name":
      return language === "ar-AR" ? item.name : item.name_en || item.name;
    case "parent":
      return language === "ar-AR" ? item.parent_name : item.parent_name_en;
    case "store_name":
      return language === "ar-AR" ? item.store_name : item.store_name_en;
    case "account_name":
      return language === "ar-AR" ? item.account_name : item.account_name_en;
    case "setting":
      return language === "ar-AR" ? item.value : item.valueEN;
    case "unit":
      return language === "ar-AR" ? item.unit_name : item.unit_name_en;
    case "product":
      return language === "ar-AR" ? item.product_name : item.product_name_en;
    case "receiver_name":
      return language === "ar-AR" ? item.receiver_name : item.receiver_name_en;
    default:
      break;
  }
};

export const IfInputsHasErrors = (toCheckState, excludes, t) => {
  let errorFields = [];
  for (let key in toCheckState) {
    if (!(excludes || []).some((s) => s === key)) {
      if (!toCheckState[key].validity) {
        errorFields.push(
          toCheckState[key].label.props
            ? toCheckState[key].label.props
            : toCheckState[key].label
        );
      }
    }
  }
  if (errorFields.length > 0) {
    const transErrs = errorFields.map((err) =>
      err.ns ? i18n.t(err.ns + ":" + err.text) : i18n.t("common:" + err)
    );
    return transErrs;
  }
  return false;
};

export const ifHasPermission = (permissions = [], permission = [""]) => {
  return permissions.some((per) => permission.includes(per));
};

export const getSRPREtype = (type) => {
  switch (type) {
    case "sales":
      return "dailyentrysales";
    case "resales":
      return "dailyentryresales";
    case "purchases":
      return "dailyentrypurchases";
    case "repurchases":
      return "dailyentryrepurchases";
    case "receipts":
      return "dailyentryreceipt";
    case "payments":
      return "dailyentrypayment";
    case "expense":
      return "dailyentryexpense";
    default:
      return "dailyentrysales";
  }
};

export const getJournalType = (type) => {
  let invType = "";
  switch (type) {
    case "sales":
    case "resales":
    case "purchases":
    case "repurchases":
      invType = "invoice";
      break;
    case "receipt":
    case "payment":
    case "expense":
      invType = "voucher";
      break;
    default:
      invType = null;
  }

  return invType;
};

export const getStoreById = (id) => {
  return Globals.stores.find((store) => store.id === id);
};

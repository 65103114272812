import * as actionTypes from "./types";
import axios from "../../util/axiosPublic";
import { axios as axiosAuth } from "../../util/axiosAuth";
import { createCookie, eraseCookie } from "../../util/cookies";
import { setAuthHeader } from "../../util/axiosAuth";
import { getProfile } from "./profile";

const loading = () => {
  return {
    type: actionTypes.AUTH_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.AUTH_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};

export const login = (username, password, remember) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post("/login", { username, password });
      if (res.data.errors) {
        // eslint-disable-next-line
        throw { response: res };
      }
      createCookie(
        "auth",
        JSON.stringify({
          token: res.data.token,
          expired: res.data.expired,
          token_origine: res.data.token_origine,
        })
      );
      setAuthHeader(res.data.token, (cb) => {
        dispatch(getProfile());
        dispatch({
          type: actionTypes.LOGIN,
          token: res.data.token,
          expired: res.data.expired,
          token_origine: res.data.token_origine,
        });
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await axiosAuth.post("/user/logout");
      eraseCookie("auth");
      setAuthHeader(null, (cb) => {
        dispatch({ type: actionTypes.LOGOUT });
      });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

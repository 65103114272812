import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { getUnixTime } from "date-fns";
import { fireSnake } from "./globalsnakebar";
import {
  calculateDiscount,
  calculateSingleTotalWithTax,
  calculateTaxTotal,
} from "../../util/calculators";
import i18n from "../../i18n";

const loading = () => {
  return {
    type: actionTypes.PURCHASES_LOADING,
  };
};
const setErrors = (err) => {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch({ type: actionTypes.PURCHASES_ERRORS, errors: null });
    }, 6000);
    dispatch({
      type: actionTypes.PURCHASES_ERRORS,
      errors: err && err.data ? err.data : err,
    });
  };
};
/*Purchases */
export const getPurchaseInvoice = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/purchases/${id}?expand=items,supplier`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getPurchaseInvoices = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    const orderDirection = query.orderDirection === "asc" ? "-" : "";
    const orderQuery = query.orderBy ? query.orderBy.field : "id";
    try {
      const res = await axios.get(
        `/purchases?expand=items,supplier&per-page=${query.pageSize}&page=${
          query.page + 1
        }&sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_PURCHASES, purchases: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addPurchaseItem = (supplierInfo, productInfo, selected) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      if (!selected) {
        //New draft
        const draftBody = { status: 0, ...getSupplierValues(supplierInfo) };
        const draft = await axios.post(
          `/purchases?expand=items,customer`,
          draftBody
        );
        const itemBody = {
          invoice_id: draft.data.id,
          ...getProductValues(productInfo),
        };
        const item = await axios.post(`/purchasesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...draft.data, items: [...draft.data.items, item.data] };
      } else {
        //Existing draft
        const itemBody = {
          invoice_id: selected.id,
          ...getProductValues(productInfo),
        };
        const item = await axios.post(`/purchasesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...selected, items: [...selected.items, item.data] };
      }
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const editPurchaseItem = (productInfo, id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      const editedItem = await axios.put(
        `/purchasesitem/${id}`,
        getProductValues(productInfo)
      );
      dispatch({ type: actionTypes.UNLOAD });
      return editedItem.data;
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deletePurchaseItem = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      await axios.delete(`/purchasesitem/${id}`);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const savePurchaseInvoice = (supplierInfo, totals, type, id) => {
  //type = 0 draft & type = 1 is exported
  return async (dispatch) => {
    dispatch(loading());
    try {
      const InvoiceInfo = {
        status: type,
        ...getSupplierValues(supplierInfo),
        ...totals,
        //brutto_no_tax:0 //TODO This was told its for later
      };
      await axios.put(`/purchases/${id}`, InvoiceInfo);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deletePurchaseInvoice = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete(`/purchases/${id}`);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
let timeout;
/*Search products without store condition */
export const searchAllProducts = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(`purchases/plist?q=${query ? query : ""}`);
        dispatch({
          type: actionTypes.ALL_PRODUCT_SEARCH,
          products: res.data,
        });
      } catch (err) {
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(err.response.data));
        throw err.response ? err.response.data : err;
      }
    }, 1500);
  };
};

/*Search suppliers for purchase invoices (no pagination) */
export const searchAllSuppliers = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(`purchases/slist?q=${query ? query : ""}`);
        dispatch({
          type: actionTypes.ALL_SUPPLIERS_SEARCH,
          searchedSuppliers: res.data,
        });
      } catch (err) {
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(err.response.data));
        throw err.response ? err.response.data : err;
      }
    }, 1500);
  };
};

export const getPurchasePermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/purchases/permissions`);
      dispatch({
        type: actionTypes.SET_PURCHASE_PERMISSIONS,
        purchasesPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const savePurchasePermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`/purchases/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_PURCHASE_PERMISSIONS,
          purchasesPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/*Purchase Returns (Repurchases) */
export const getRepurchaseInvoice = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/repurchases/${id}?expand=items,supplier`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getRepurchaseInvoices = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    const orderDirection = query.orderDirection === "asc" ? "-" : "";
    const orderQuery = query.orderBy ? query.orderBy.field : "id";
    try {
      const res = await axios.get(
        `/repurchases?expand=items,supplier&per-page=${query.pageSize}&page=${
          query.page + 1
        }&sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_REPURCHASES, repurchases: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteRepurchaseInvoice = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete(`/repurchases/${id}`);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const searchPurchasedInvoices = (storeId, query) => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout);
    if (!query) {
      return dispatch(clearRepurchasedInvoices());
    }
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(
          `/repurchases/invoicelist?store_id=${storeId}&q=${query ? query : ""}`
        );
        dispatch({
          type: actionTypes.SET_REPURCHASE_INV_LIST,
          searchedRepurchases: res.data,
        });
      } catch (err) {
        const error = err.response ? err.response.data : err;
        dispatch(setErrors(error));
        dispatch(GeneralFiresnake(error));
        throw error;
      }
    }, 1500);
  };
};
export const clearRepurchasedInvoices = () => {
  return {
    type: actionTypes.SET_REPURCHASE_INV_LIST,
    searchedRepurchases: [],
  };
};
export const searchPurchasedInvoiceProducts = (invoiceId, query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(
        `/repurchases/plist?invoice_id=${invoiceId}&q=${query ? query : ""}`
      );
      dispatch({
        type: actionTypes.UNLOAD,
      });
      return res.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
      throw error;
    }
  };
};

export const addRepurchaseItem = (invoiceInfo, productInfo, selected) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      if (!selected) {
        //New draft
        const draftBody = { status: 0, ...getReturnInvoiceValues(invoiceInfo) };
        const draft = await axios.post(
          `/repurchases?expand=items,supplier`,
          draftBody
        );
        const itemBody = {
          invoice_id: draft.data.id,
          ...getReturnProductValues(productInfo),
        };

        const item = await axios.post(`/repurchasesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...draft.data, items: [...draft.data.items, item.data] };
      } else {
        //Existing draft
        const itemBody = {
          invoice_id: selected.id,
          ...getReturnProductValues(productInfo),
        };
        const item = await axios.post(`/repurchasesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...selected, items: [...selected.items, item.data] };
      }
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const editRepurchaseItem = (state, item) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      let body;
      if (state.product.value.id) {
        //product was change
        body = {
          ...getReturnProductValues(state),
        };
      } else {
        //only quantity changed
        const quantity = state.quantity.value;
        body = {
          quantity: quantity,
          discount: (+item.discount / +item.quantity) * quantity,
          tax_total: (+item.tax_total / +item.quantity) * quantity,
          total: (+item.total / +item.quantity) * quantity,
        };
      }
      const editedItem = await axios.put(`/repurchasesitem/${item.id}`, body);
      dispatch({ type: actionTypes.UNLOAD });
      return editedItem.data;
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteRepurchaseItem = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      await axios.delete(`/repurchasesitem/${id}`);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveRepurchaseInvoice = (invoiceInfo, totals, type, id) => {
  //type = 0 draft & type = 1 is exported
  return async (dispatch) => {
    dispatch(loading());
    try {
      const InvoiceInfo = {
        status: type,
        ...getReturnInvoiceValues(invoiceInfo),
        ...totals,
        //brutto_no_tax:0 //TODO This was told its for later
      };

      await axios.put(`/repurchases/${id}`, InvoiceInfo);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const getRepurchasePermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/repurchases/permissions`);
      dispatch({
        type: actionTypes.SET_REPURCHASE_PERMISSIONS,
        repurchasesPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveRepurchasePermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`/repurchases/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_REPURCHASE_PERMISSIONS,
          repurchasesPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/*Suppliers */
export const getSuppliers = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    const orderDirection = query.orderDirection === "asc" ? "-" : "";
    const orderQuery = query.orderBy ? query.orderBy.field : "created_at";
    try {
      const res = await axios.get(
        `/supplier?per-page=${query.pageSize}&page=${query.page + 1}&sort=${
          orderDirection + orderQuery
        }`
      );
      dispatch({ type: actionTypes.SET_SUPPLIERS, suppliers: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addEditSupplier = (supplier, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/supplier/" + supplier.id, { ...supplier });
      } else {
        await axios.post("/supplier", { ...supplier });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteSupplier = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/supplier/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const getSupplierPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`supplier/permissions`);
      dispatch({
        type: actionTypes.SET_SUPPLIER_PERMISSIONS,
        supplierPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveSupplierPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`supplier/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_SUPPLIER_PERMISSIONS,
          supplierPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

//Helpers
const getSupplierValues = (supplier) => {
  const checkPaymentCash = () =>
    supplier.paid_type.value === "cash" ||
    supplier.paid_type.value === "postpay";

  return {
    store_id: 2, //TODO to bring the store id dynamiclly somehow, maybe settings/config
    supplier_id: supplier.supplier.value ? supplier.supplier.value.id : "",
    invoice_date: getUnixTime(supplier.invoice_date.value),
    p_invoice_nr: supplier.p_invoice_nr.value,
    paid_type: supplier.paid_type.value,
    check_nr: supplier.check_nr.value,
    check_date: checkPaymentCash()
      ? ""
      : getUnixTime(supplier.check_date.value),
    bank_id: checkPaymentCash() ? "" : supplier.bank_id.value,
    description: supplier.description.value,
  };
};
const getProductValues = (item) => {
  const discount = calculateDiscount(item.discount.value, item.quantity.value);
  const SingleTotalWithTax = calculateSingleTotalWithTax(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  const TaxTotal = calculateTaxTotal(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  return {
    product_id: +item.product.value.id,
    quantity: +item.quantity.value,
    unit_price: +item.unit_price.value,
    tax_id: +item.product.value.tax_id,
    tax_total: TaxTotal,
    discount: discount,
    total: SingleTotalWithTax,
  };
};

const getReturnInvoiceValues = (state) => {
  const {
    b_invoice_nr,
    store_id,
    supplier_id,
    invoice_billing_date,
  } = state.invoice.value;

  const ifCash = () =>
    state.paid_type.value === "cash" || state.paid_type.value === "postpay";

  return {
    b_invoice_nr: b_invoice_nr,
    store_id: store_id,
    supplier_id: supplier_id,
    invoice_billing_date: +invoice_billing_date,
    invoice_date: getUnixTime(state.invoice_date.value),
    paid_type: state.paid_type.value,
    check_nr: state.check_nr.value,
    check_date: ifCash() ? "" : getUnixTime(state.check_date.value),
    bank_id: ifCash() ? "" : state.bank_id.value,
    description: state.description.value,
  };
};

const getReturnProductValues = (state) => {
  const {
    id,
    product_id,
    unit_price,
    tax_id,
    tax_value,
    discount,
    quantity,
  } = state.product.value;
  const quantityToReturn = state.quantity.value;

  // getting discount per quantity from the totalDiscount/totalQuantity
  const reversedDiscount = +discount / +quantity;

  const TaxTotal = calculateTaxTotal(
    quantityToReturn,
    unit_price,
    reversedDiscount,
    tax_value
  );

  const SingleTotalWithTax = calculateSingleTotalWithTax(
    quantityToReturn,
    unit_price,
    reversedDiscount,
    tax_value
  );

  return {
    item_id: id,
    product_id: product_id,
    tax_id: tax_id,
    quantity: quantityToReturn,
    unit_price: unit_price,
    discount: reversedDiscount * quantityToReturn,
    tax_total: TaxTotal,
    total: SingleTotalWithTax,
  };
};

const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", i18n.t("common:uncommonerror")));
  };
};

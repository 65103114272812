import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { useSelector } from "react-redux";
import { ifHasPermission } from "../../../util/generals";

const ChartSkeleton = () => {
  const { t } = useTranslation();
  const { width } = useWindowResize();
  const permissions = useSelector((state) => state.profile.permissions);
  const divider = ifHasPermission(permissions, [
    "manageaccountreport",
    "showaccountreport",
  ])
    ? 2
    : 1;
  const numbersPerWidth = Math.round(width / (divider * 200));
  const array = "s".repeat(numbersPerWidth).split("");

  return (
    <div className="ltr">
      <div className="flex">
        <Skeleton height={330} width={3} style={{ marginBottom: -55 }} />
        <div className="flex absolute">
          <Typography
            variant="caption"
            color="textSecondary"
            className="position__centerilize"
          >
            {t("common:loading")}
          </Typography>
          {array.map((line, i) => (
            <Skeleton
              key={i}
              height={330}
              width={1}
              style={{ marginLeft: 120 }}
            />
          ))}
        </div>
        <div className="fullwidth flex ver flex-justify-evenly">
          <Skeleton height={1} style={{ visibility: "hidden" }} />
          <Skeleton height={1} />
          <Skeleton height={1} />
          <Skeleton height={1} />
          <Skeleton height={1} />
        </div>
      </div>
      <Skeleton height={5} width="100%" />
    </div>
  );
};

export default ChartSkeleton;

import React from "react";
import { Grid, Paper } from "@material-ui/core";
import BarChart from "../Charts/BarChart";
import FiscalChart from "../Charts/FiscalChart";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ifHasPermission } from "../../../../util/generals";

const StatisticsCharts = () => {
  const { t } = useTranslation();
  const permissions = useSelector((state) => state.profile.permissions);
  const dataTypes = [
    {
      value: "sales",
      text: t("statistics:sales"),
      permission: "showsalesreport",
    },
    {
      value: "resales",
      text: t("statistics:resales"),
      permission: "showresalesreport",
    },
    {
      value: "purchases",
      text: t("statistics:purchases"),
      permission: "showpurchasesreport",
    },
    {
      value: "repurchases",
      text: t("statistics:repurchases"),
      permission: "showrepurchasesreport",
    },
    {
      value: "expense",
      text: t("statistics:expense"),
      permission: "showexpensereport",
    },
    {
      value: "payment",
      text: t("statistics:payment"),
      permission: "showpaymentreport",
    },
    {
      value: "receipt",
      text: t("statistics:receipt"),
      permission: "showreceiptreport",
    },
  ];

  const permissionedData = dataTypes.filter((data) =>
    permissions.includes(data.permission)
  );

  return (
    <Grid container spacing={2} alignContent="center">
      <Grid
        item
        md={
          !ifHasPermission(permissions, [
            "manageaccountreport",
            "showaccountreport",
          ])
            ? 12
            : 6
        }
        sm={12}
        xs={12}
      >
        <Paper className="p">
          <BarChart dataTypes={permissionedData} />
        </Paper>
      </Grid>
      {ifHasPermission(permissions, [
        "manageaccountreport",
        "showaccountreport",
      ]) && (
        <Grid item md={6} sm={12} xs={12}>
          <Paper className="p">
            <FiscalChart />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default StatisticsCharts;

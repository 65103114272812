import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CopyrightIcon from "@material-ui/icons/Copyright";
import MoreIcon from "@material-ui/icons/MoreVert";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <AppBar
      component="footer"
      position="fixed"
      color="primary"
      className={classes.appBar}
    >
      <Toolbar style={{ minHeight: 50 }}>
        <Typography variant="caption">
          {t("settings:footercopyright")}
          <CopyrightIcon style={{ fontSize: 12 }} />
        </Typography>
        <div className={classes.grow} />
        <IconButton edge="end" color="inherit">
          <MoreIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    top: "auto",
    bottom: 0,
    zIndex: -1
  },
  grow: {
    flexGrow: 1
  }
}));

export default Footer;

import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
// import { fireSnake } from "./globalsnakebar";
// import i18n from "../../i18n";

const setLoading = () => {
  return {
    type: actionTypes.ACCOUNTS_LOADING,
  };
};

const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};

const setErrors = (err) => {
  return {
    type: actionTypes.ACCOUNTS_ERROR,
    errors: err && err.data ? err.data : err,
  };
};

/*
 *Transfers
 */
export const getAccountTransfers = (query) => {
  return async (dispatch) => {
    dispatch(setLoading());
    let orderQuery = "created_at";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "created_at";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/moneytransfer?sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_ACC_TRANSFERS, transfers: res.data });

      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getTransferFromAccounts = () => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(`/moneytransfer/from_account`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getTransferToAccounts = (from) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(`/moneytransfer/to_account/${from}`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditAccountTransfer = (body, editId) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      if (editId) {
        // await axios.put(`/moneytransfer/${editId}`, body);
      } else {
        await axios.post("/moneytransfer", body);
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const getAccountTransfersPermissions = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.get(`moneytransfer/permissions`);
      dispatch({
        type: actionTypes.SET_ACC_TRANS_PERMISSIONS,
        transfersPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

export const saveAccountTransfersPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.post(`moneytransfer/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_ACC_TRANS_PERMISSIONS,
          transfersPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/**
 * Closure
 */
export const getSalesClosures = (query) => {
  return async (dispatch) => {
    dispatch(setLoading());
    let orderQuery = "created_at";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "created_at";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/salesfund?sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_SALES_CLOSURES, closures: res.data });

      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getAccountListToClose = () => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      const res = await axios.get(`/salesfund/listaccount`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getStoreListPerAccountToClose = (account_id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(`/salesfund/liststore/${account_id}`);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const saveClosure = (body) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.post(`/salesfund`, body);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getSalesClosuresPermissions = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.get(`salesfund/permissions`);
      dispatch({
        type: actionTypes.SET_SALES_CLOSURES_PERMISSIONS,
        closuresPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

export const saveSalesClosuresPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.post(`salesfund/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_SALES_CLOSURES_PERMISSIONS,
          closuresPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

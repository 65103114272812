import * as actionTypes from "../actions/types";

const initialState = {
  transfers: [],
  closures: [],
  permissions: {
    transfers: [],
    closures: [],
  },
  loading: false,
  itemLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNTS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
        errors: null,
      };
    case actionTypes.SET_ACC_TRANSFERS:
      return {
        ...state,
        transfers: action.transfers,
        loading: false,
      };
    case actionTypes.SET_ACC_TRANS_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          transfers: action.transfersPermissions,
        },
      };
    case actionTypes.SET_SALES_CLOSURES:
      return {
        ...state,
        closures: action.closures,
        loading: false,
      };
    case actionTypes.SET_SALES_CLOSURES_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          closures: action.closuresPermissions,
        },
      };
    case actionTypes.ACCOUNTS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};

import React, { useContext } from "react";
import { Paper, Button, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import themeContext from "../../contexts/theme/themeContext";

const MenuItem = ({
  title,
  icon,
  link,
  primary,
  secondary,
  third,
  textClasses,
  textStyles,
  onBtnClick,
  horizontal,
  variant,
}) => {
  const classes = useStyle();
  const { gradient_primary, gradient_secondary, gradient_third } = useContext(
    themeContext
  );
  const getBg = () => {
    if (primary) {
      return gradient_primary;
    } else if (secondary) {
      return gradient_secondary;
    } else if (third) {
      return gradient_third;
    }
    return gradient_primary;
  };
  return (
    <Link className={classes.title} to={link || "#"}>
      <Button
        className={`${classes.root} fullheight`}
        onClick={onBtnClick}
        classes={{ label: "fullheight" }}
      >
        <Paper
          elevation={3}
          className={`${classes.paper} ${getBg()} fullheight`}
        >
          {horizontal ? (
            <Typography
              variant={variant ? variant : "h2"}
              className={`text-light p-square ${textClasses}`}
              style={textStyles}
              component="div"
            >
              {icon} {title}
            </Typography>
          ) : (
            <>
              <Typography
                variant={variant ? variant : "h2"}
                className={`text-light p-square ${textClasses}`}
                style={textStyles}
                component="div"
              >
                {title}
              </Typography>
              {icon}
            </>
          )}
        </Paper>
      </Button>
    </Link>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 0,
    "&:hover": {
      top: -5,
      opacity: 0.9,
    },
    transitionProperty: ["top", "opacity"],
  },
  paper: {
    flex: 1,
    // background: "linear-gradient(326deg, #7a95b4 0%, #2E5077 48%);"
  },
  title: {
    textDecoration: "none",
    color: "#eee",
  },
}));
export default MenuItem;

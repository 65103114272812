import * as actionsTypes from "../actions/types";

const initialState = {
  journals: null,
  tree: [],
  permissions: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.REPORTS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_ACC_TREE:
      return {
        ...state,
        loading: false,
        tree: action.tree,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
      };
    case actionsTypes.SET_JOURNALS:
      return {
        ...state,
        loading: false,
        journals: action.journals,
      };
    case actionsTypes.SET_REPORTS_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: action.reportPermissions,
      };
    case actionsTypes.REPORTS_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};

import * as actionsTypes from "../actions/types";

const initialState = {
  receipts: [],
  payments: [],
  receiptReceivers: [],
  paymentReceivers: [],
  permissions: {
    receipts: [],
    payments: [],
  },
  loading: false,
  itemLoading: false,
  errors: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.VOUCHERS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionsTypes.SET_RECEIPTS:
      return {
        ...state,
        loading: false,
        receipts: action.receipts,
      };
    case actionsTypes.SET_PAYMENTS:
      return {
        ...state,
        loading: false,
        payments: action.payments,
      };
    case actionsTypes.SET_RECEIPTS_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          receipts: action.receiptsPermissions,
        },
      };
    case actionsTypes.SET_PAYMENTS_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          payments: action.paymentsPermissions,
        },
      };
    case actionsTypes.SET_RECEIPT_RECEIVERS:
      return {
        ...state,
        loading: false,
        receiptReceivers: action.receiptReceivers,
      };
    case actionsTypes.SET_PAYMENT_RECEIVERS:
      return {
        ...state,
        loading: false,
        paymentReceivers: action.paymentReceivers,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.VOUCHERS_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };

    default:
      return state;
  }
};

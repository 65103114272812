import * as actionsTypes from "../actions/types";

const initialState = {
  expenses: [],
  expenseGroups: [],
  clist: [],
  elist: [],
  permissions: {
    expenses: [],
    expenseGroups: [],
  },
  loading: false,
  itemLoading: false,
  errors: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.EXPENSES_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionsTypes.SET_EXPENSE:
      return {
        ...state,
        loading: false,
        expenses: action.expenses,
      };
    case actionsTypes.SET_EXPENSE_GROUPS:
      return {
        ...state,
        loading: false,
        expenseGroups: action.groups,
      };
    case actionsTypes.SET_EXPENSES_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          expenses: action.expensePermissions,
        },
      };
    case actionsTypes.SET_EXPENSES_GROUP_PERMISSIONS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...state.permissions,
          expenseGroups: action.groupPermissions,
        },
      };
    case actionsTypes.SET_CLIST:
      return {
        ...state,
        itemLoading: false,
        clist: action.clist,
      };
    case actionsTypes.SET_ELIST:
      return {
        ...state,
        itemLoading: false,
        elist: action.elist,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.EXPENSES_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };

    default:
      return state;
  }
};

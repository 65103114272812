import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  ListSubheader,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonSpinner from "../Spinners/ButtonSpinner";

const SelectInput = (props) => {
  const {
    options,
    name,
    required,
    getResult,
    label,
    errMsg,
    disabled,
    initialValue,
    setTouched,
    helperText,
    serverError,
    variant,
    loading,
    placeholder,
  } = props;

  const [state, setState] = useState({
    value: initialValue,
    touched: false,
    valid: false,
    errorMsg: "",
  });

  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    if (label) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [label]);
  useEffect(() => {
    if (setTouched && state.value === "") {
      setState({ ...state, touched: true });
    }
    // eslint-disable-next-line
  }, [setTouched]);
  const showServerErr = useCallback(() => {
    setState({
      ...state,
      valid: false,
      touched: true,
      errorMsg: serverError,
    });
    // eslint-disable-next-line
  }, [serverError]);
  useEffect(() => {
    if (serverError) {
      showServerErr();
    }
  }, [serverError, showServerErr]);
  const handleTouched = () => {
    if (!state.touched) {
      setState({ ...state, touched: true, errorMsg: required ? errMsg : "" });
    }
  };
  const handleChange = (e) => {
    let val = e.target.value;
    let isValid = true;
    if (val === "") {
      isValid = false;
    }
    if (val) {
      setState({ ...state, value: val, valid: isValid });
      getResult(val, isValid, name);
    }
  };

  return (
    <>
      <FormControl
        variant={variant || "outlined"}
        fullWidth
        error={
          (required && state.touched && !state.valid && !initialValue) ||
          (serverError && true)
        }
        // style={{ minWidth: 230 }}
        disabled={disabled}
      >
        {label && <InputLabel ref={inputLabel}>{label}</InputLabel>}
        <Select
          value={state.value}
          onBlur={handleTouched}
          onChange={handleChange}
          labelWidth={labelWidth}
          IconComponent={loading ? ButtonSpinner : ExpandMoreIcon}
          placeholder={placeholder}
        >
          {options.map((op) =>
            op.subheader ? (
              <ListSubheader value="" key={op.value} className={op.classes}>
                {op.text}
              </ListSubheader>
            ) : (
              op.value !== null && (
                <MenuItem value={op.value} key={op.value}>
                  {op.text}
                </MenuItem>
              )
            )
          )}
        </Select>
      </FormControl>
      <FormHelperText style={{ color: "#931621" }}>
        {state.touched && !state.valid && !initialValue && state.errorMsg}
      </FormHelperText>
      {serverError && initialValue && (
        <FormHelperText style={{ color: "#931621" }}>
          {serverError}
        </FormHelperText>
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
};

export default SelectInput;

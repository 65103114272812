import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { fireSnake } from "./globalsnakebar";
import i18n from "../../i18n";

const setLoading = () => {
  return {
    type: actionTypes.STORES_LOADING,
  };
};

const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};

const setErrors = (err) => {
  return async (dispatch) => {
    setTimeout(() => {
      dispatch({ type: actionTypes.STORE_ERROR, errors: null });
    }, 6000);
    dispatch({
      type: actionTypes.STORE_ERROR,
      errors: err && err.data ? err.data : err,
    });
  };
};
export const getStores = (query) => {
  return async (dispatch) => {
    dispatch(setLoading());
    let orderQuery = "created_at";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "created_at";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/store?expand=productscount,stores?sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_STORES, stores: res.data });

      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const addEditStore = (store, editId) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      if (editId) {
        await axios.put("/store/" + editId, store);
      } else {
        await axios.post("/store", store);
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteStore = (id) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      await axios.delete("/store/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const getStorePermissions = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.get(`store/permissions`);

      dispatch({
        type: actionTypes.SET_STORE_PERMISSIONS,
        storePermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

export const saveStorePermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.post(`store/permissions`, permissions);

      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_STORE_PERMISSIONS,
          storePermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/**
 * Store Transfers
 */
export const getStoreTransfers = (query) => {
  return async (dispatch) => {
    dispatch(setLoading());
    let orderQuery = "transfer_date";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "transfer_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/producttransfer?sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_TRANSFERS, transfers: res.data });

      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const addEditTransfer = (transfer, editId) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      if (editId) {
        await axios.put("/producttransfer/" + editId, transfer);
      } else {
        await axios.post("/producttransfer", transfer);
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteTransfer = (id) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      await axios.delete("/producttransfer/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const searchFromStore = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      const res = await axios.get(
        `/producttransfer/fromstorelist?q=${query ? query : ""}`
      );
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      dispatch(GeneralFiresnake(err.response ? err.response.data : err));
      throw err.response ? err.response.data : err;
    }
  };
};

export const searchToStore = (query, from_id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(
        `/producttransfer/tostorelist?parent_id=${from_id}&q=${
          query ? query : ""
        }`
      );
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      dispatch(GeneralFiresnake(err.response ? err.response.data : err));
      throw err.response ? err.response.data : err;
    }
  };
};

export const searchProductFromStore = (query, from_id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const res = await axios.get(
        `/producttransfer/plist?from_store_id=${from_id}&q=${
          query ? query : ""
        }`
      );
      dispatch({ type: actionTypes.UNLOAD });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      dispatch(GeneralFiresnake(err.response ? err.response.data : err));
      throw err.response ? err.response.data : err;
    }
  };
};

const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", i18n.t("common:uncommonerror")));
  };
};

export const getStoreTransfersPermissions = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.get(`producttransfer/permissions`);
      dispatch({
        type: actionTypes.SET_TRANSFERS_PERMISSIONS,
        transfersPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

export const saveStoreTransfersPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.post(`producttransfer/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_TRANSFERS_PERMISSIONS,
          transfersPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

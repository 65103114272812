import { combineReducers } from "redux";
import authReducer from "./auth";
import profileReducer from "./profile";
import storesReducer from "./stores";
import salesReducer from "./sales";
import productsReducer from "./products";
import purchaseReducer from "./purchases";
import expensesReducer from "./expenses";
import vouchersReducer from "./vouchers";
import settingsReducer from "./settings";
import reportsReducer from "./reports";
import offersReducer from "./offers";
import accountsReducer from "./accounts";
import statisticsReducer from "./statistics";
import snakebarReducer from "./globalsnakebar";

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  stores: storesReducer,
  purchases: purchaseReducer,
  products: productsReducer,
  sales: salesReducer,
  expenses: expensesReducer,
  vouchers: vouchersReducer,
  settings: settingsReducer,
  reports: reportsReducer,
  offers: offersReducer,
  accounts: accountsReducer,
  statistics: statisticsReducer,
  snakebar: snakebarReducer,
});

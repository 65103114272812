import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import * as actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { ifHasPermission, pickLangVal } from "../../util/generals";
import themeContext from "../../contexts/theme/themeContext";
import { useTranslation } from "react-i18next";
import SwitchButtons from "../UI/Buttons/SwitchButtons/SwitchButtons";
import { Box, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
const langBtns = [
  { name: "ar-AR", content: "ar" },
  { name: "en-US", content: "en" },
];

const HeaderBar = ({ history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const profile = useSelector((state) => state.profile);
  const sitename = settings.settings.generals.find(
    (set) => set.name === "sitename"
  );
  const { language, toggleLanguage } = useContext(themeContext);
  const { t } = useTranslation();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      await dispatch(actions.logout());
      history.replace("/login");
    } catch (err) {
      //
    }
  };
  const handleChangeLanguage = async (value, name) => {
    try {
      await dispatch(
        actions.editGeneralSetting(name, { value: value }, "appearances")
      );
      toggleLanguage(value);
      handleMenuClose();
    } catch (err) {
      dispatch(
        actions.fireSnake(
          "error",
          err.message ? err.message : t("common:uncommonerror")
        )
      );
    }
  };
  const langSwitcher = (
    <Box style={{ marginTop: 20 }}>
      <SwitchButtons
        buttons={langBtns}
        state={language}
        onClick={(val) => {
          if (val !== language) handleChangeLanguage(val, "frontendlanguage");
        }}
        disabled={settings.loading}
      />
    </Box>
  );
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {ifHasPermission(profile.permissions, ["settings"]) && (
        <MenuItem>
          <Link to="/settings/general">{t("settings:generalsettings")}</Link>
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>{t("common:logout")}</MenuItem>
      {langSwitcher}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {ifHasPermission(profile.permissions, ["settings"]) && (
        <MenuItem onClick={handleMenuClose}>
          <Link to="/settings/general">{t("settings:generalsettings")}</Link>
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>{t("common:logout")}</MenuItem>
      {langSwitcher}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="relative">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            {sitename
              ? pickLangVal(language, sitename, "setting")
              : "وكائد - البرنامج المحاسبي"}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
            <Button
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              startIcon={<AccountCircle />}
              endIcon={<ExpandMoreRoundedIcon />}
            >
              <Typography variant="body2">
                {profile.user_name || "Unknwon"}
              </Typography>
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default withRouter(HeaderBar);

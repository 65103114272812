import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { getUnixTime } from "date-fns";
import { fireSnake } from "./globalsnakebar";
import {
  calculateDiscount,
  calculateSingleTotalWithTax,
  calculateTaxTotal,
} from "../../util/calculators";
import i18n from "../../i18n";

const loading = () => {
  return {
    type: actionTypes.SALES_LOADING,
  };
};

const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};

const setErrors = (err) => {
  // return async (dispatch) => {
  //   setTimeout(() => {
  //     dispatch({ type: actionTypes.SALES_ERRORS, errors: null });
  //   }, 6000);
  //   dispatch({
  //     type: actionTypes.SALES_ERRORS,
  //     errors: err && err.data ? err.data : err,
  //   });
  // };
  return {
    type: actionTypes.SALES_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};

/*Sales */
export const getSaleInvoice = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(
        `/sales/${id}?expand=customer,items,saledBy,repairedBy,store`
      );
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getSaleInvoices = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "invoice_date";
    let orderDirection = "-";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "invoice_date";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/sales?expand=customer,items,saledBy,repairedBy,store&per-page=${
          query.pageSize
        }&page=${query.page + 1}&sort=${orderDirection + orderQuery}`
      );
      dispatch({
        type: actionTypes.SET_SALES,
        sales: res.data.sales,
      });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addSaleItem = (customerInfo, productInfo, selected) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      if (!selected) {
        //New draft
        const draftBody = {
          status: 0,
          ...getInvoiceValues({
            ...customerInfo,
            store_id: productInfo.store_id,
          }),
        };
        const draft = await axios.post(
          `/sales?expand=customer,items,saledBy,repairedBy,store`,
          draftBody
        );

        const itemBody = {
          invoice_id: draft.data.id,
          ...getProductValues(productInfo),
        };
        const item = await axios.post(`/salesitem`, itemBody);

        return { ...draft.data, items: [...draft.data.items, item.data] };
      } else {
        //Existing draft
        const itemBody = {
          invoice_id: selected.id,
          ...getProductValues(productInfo),
        };
        const item = await axios.post(`/salesitem`, itemBody);

        return { ...selected, items: [...selected.items, item.data] };
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const editSaleItem = (productInfo, id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      const editedItem = await axios.put(
        `/salesitem/${id}`,
        getProductValues(productInfo)
      );
      dispatch({ type: actionTypes.UNLOAD });
      return editedItem.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteSalesItem = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      await axios.delete(`/salesitem/${id}`);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveSaleInvoice = (customerInfo, totals, type, id) => {
  //type = 0 draft | type = 1 export
  return async (dispatch) => {
    dispatch(loading());
    try {
      const InvoiceInfo = {
        status: type,
        ...getInvoiceValues(customerInfo),
        ...totals,
        //brutto_no_tax:0 //TODO This was told its for later
      };
      await axios.put(`/sales/${id}`, InvoiceInfo);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteSaleInvoice = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("sales/" + id);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

let timeout;
export const searchCustomers = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(`/sales/clist?q=${query ? query : ""}`);
        dispatch({
          type: actionTypes.ALL_CUSTOMERS_SEARCH,
          customers: res.data,
        });
        dispatch({ type: actionTypes.UNLOAD });
      } catch (err) {
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(err.response ? err.response.data : err));
        throw err.response ? err.response.data : err;
      }
    }, 1500);
  };
};
export const searchSaleProducts = (search, storeId, customer_id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    clearTimeout(timeout);

    //Slove no-store id when user has no stores
    let store = storeId;
    if (!Number.isInteger(store)) {
      store = 0;
    }

    let query = `?q=${search}&store_id=${store}&customer_id=${customer_id}`;
    if (!customer_id) {
      query = `?q=${search}&store_id=${store}`;
    }
    timeout = await setTimeout(async () => {
      try {
        const res = await axios.get(`/sales/plist${query}`);

        dispatch({
          type: actionTypes.SALE_PRODUCT_SEARCH,
          products: res.data,
        });
        dispatch({ type: actionTypes.UNLOAD });
      } catch (err) {
        dispatch(setErrors(err.response));
        dispatch(GeneralFiresnake(err.response ? err.response.data : err));
        throw err.response ? err.response.data : err;
      }
    }, 1500);
  };
};
export const clearSearchSaleProduct = () => {
  return {
    type: actionTypes.SALE_PRODUCT_SEARCH,
    products: [],
  };
};
export const getSalesPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`sales/permissions`);
      dispatch({
        type: actionTypes.SET_SALES_PERMISSIONS,
        salesPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveSalesPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`sales/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_SALES_PERMISSIONS,
          salesPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/*Sale Returns (Resales) */
export const getResaleInvoice = (id = 0) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(
        `/resales/${id}?expand=items,customer,saledBy`
      );
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getResaleInvoices = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    const orderDirection = query.orderDirection === "asc" ? "-" : "";
    const orderQuery = query.orderBy ? query.orderBy.field : "id";
    try {
      const res = await axios.get(
        `/resales?expand=items,customer,saledBy&per-page=${
          query.pageSize
        }&page=${query.page + 1}&sort=${orderDirection + orderQuery}`
      );
      dispatch({ type: actionTypes.SET_RESALES, resales: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteResaleInvoice = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete(`/resales/${id}`);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const searchSoldInvoices = (storeId, query) => {
  return async (dispatch) => {
    dispatch(loading());
    clearTimeout(timeout);
    if (!query) {
      return dispatch(clearResalesInvoices());
    }
    timeout = setTimeout(async () => {
      try {
        const res = await axios.get(
          `/resales/invoicelist?store_id=${storeId}&q=${query ? query : ""}`
        );
        dispatch({
          type: actionTypes.SET_RESALES_INV_LIST,
          searchedResales: res.data,
        });
      } catch (err) {
        const error = err.response ? err.response.data : err;
        dispatch(setErrors(error));
        dispatch(GeneralFiresnake(error));
        throw error;
      }
    }, 1500);
  };
};
export const clearResalesInvoices = () => {
  return {
    type: actionTypes.SET_RESALES_INV_LIST,
    searchedResales: [],
  };
};

export const searchSoldInvoiceProducts = (invoiceId, query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(
        `/resales/plist?invoice_id=${invoiceId}&q=${query ? query : ""}`
      );
      dispatch({
        type: actionTypes.UNLOAD,
      });

      return res.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      dispatch(GeneralFiresnake(error));
      throw error;
    }
  };
};

export const addResaleItem = (invoiceInfo, productInfo, selected) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      if (!selected) {
        //New draft
        const draftBody = { status: 0, ...getReturnInvoiceValues(invoiceInfo) };
        const draft = await axios.post(
          `/resales?expand=items,customer,saledBy`,
          draftBody
        );

        const itemBody = {
          invoice_id: draft.data.id,
          ...getReturnProductValues(productInfo),
        };

        const item = await axios.post(`/resalesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...draft.data, items: [...draft.data.items, item.data] };
      } else {
        //Existing draft
        const itemBody = {
          invoice_id: selected.id,
          ...getReturnProductValues(productInfo),
        };

        const item = await axios.post(`/resalesitem`, itemBody);
        dispatch({ type: actionTypes.UNLOAD });
        return { ...selected, items: [...selected.items, item.data] };
      }
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const editResaleItem = (state, item) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      let body;
      if (state.product.value.item_id) {
        //product was change
        body = {
          ...getReturnProductValues(state),
        };
      } else {
        //only quantity changed

        const quantity = state.quantity.value;
        body = {
          quantity: quantity,
          discount: (+item.discount / +item.quantity) * quantity,
          tax_total: (+item.tax_total / +item.quantity) * quantity,
          total: (+item.total / +item.quantity) * quantity,
        };
      }
      const editedItem = await axios.put(`/resalesitem/${item.id}`, body);
      dispatch({ type: actionTypes.UNLOAD });
      return editedItem.data;
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const deleteResaleItem = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ITEM_LOADING });
    try {
      await axios.delete(`/resalesitem/${id}`);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch({ type: actionTypes.UNLOAD });
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const saveResaleInvoice = (invoiceInfo, totals, type, id) => {
  //type = 0 draft & type = 1 is exported
  return async (dispatch) => {
    dispatch(loading());
    try {
      const InvoiceInfo = {
        status: type,
        ...getReturnInvoiceValues(invoiceInfo),
        ...totals,
        //brutto_no_tax:0 //TODO This was told its for later
      };
      await axios.put(`/resales/${id}`, InvoiceInfo);
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const getResalePermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`/resales/permissions`);
      dispatch({
        type: actionTypes.SET_RESALES_PERMISSIONS,
        resalesPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

export const saveResalePermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`/resales/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_RESALES_PERMISSIONS,
          resalesPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

/*Customer related actions*/
export const getCustomers = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    let orderQuery = "created_at";
    let orderDirection = "";
    if (query) {
      orderQuery = query.orderBy ? query.orderBy.field : "created_at";
      orderDirection = query.orderDirection === "asc" ? "" : "-";
    }
    try {
      const res = await axios.get(
        `/customer?per-page=${query.pageSize}&page=${query.page + 1}&sort=${
          orderDirection + orderQuery
        }&expand=salesMan`
      );
      dispatch({
        type: actionTypes.SET_CUSTOMERS,
        customers: res.data.customer,
      });

      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const addEditCustomer = (customer, edit) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      if (edit) {
        await axios.put("/customer/" + customer.id, { ...customer });
        dispatch({ type: actionTypes.UNLOAD });
      } else {
        await axios.post("/customer", { ...customer });
        dispatch({ type: actionTypes.UNLOAD });
      }
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const addFastCustomer = (name) => {
  return async (dispatch, getState) => {
    dispatch(loading());
    try {
      const { profile } = getState();
      const res = await axios.post("/cashcustomer", { name: name });
      dispatch({ type: actionTypes.UNLOAD });
      return { ...res.data, salesman_name: profile.user_name };
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const deleteCustomer = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axios.delete("/customer/" + id);
      dispatch({ type: actionTypes.UNLOAD });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const getCustomerPermissions = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(`customer/permissions`);
      dispatch({
        type: actionTypes.SET_CUSTOMER_PERMISSIONS,
        customerPermissions: res.data,
      });
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};
export const saveCustomerPermissions = (permissions) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.post(`customer/permissions`, permissions);
      if (res.data.success) {
        dispatch({
          type: actionTypes.SET_CUSTOMER_PERMISSIONS,
          customerPermissions: permissions,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response ? err.response : err));
    }
  };
};

//Helpers
const getInvoiceValues = (invoiceInfo) => {
  const checkPaymentCash = () =>
    invoiceInfo.paid_type.value === "cash" ||
    invoiceInfo.paid_type.value === "postpay";
  return {
    customer_id: invoiceInfo.customer.value
      ? invoiceInfo.customer.value.id
      : "",
    store_id: invoiceInfo.store_id.value,
    saled_by: invoiceInfo.customer.value
      ? invoiceInfo.customer.value.salesman
      : "",
    invoice_date: getUnixTime(invoiceInfo.invoice_date.value),
    h_invoice_nr: invoiceInfo.h_invoice_nr.value,
    paid_type: invoiceInfo.paid_type.value,
    check_nr: invoiceInfo.check_nr.value,
    check_date: checkPaymentCash()
      ? ""
      : getUnixTime(invoiceInfo.check_date.value),
    bank_id: checkPaymentCash() ? "" : invoiceInfo.bank_id.value,
    description: invoiceInfo.description.value,
  };
};
const getProductValues = (item) => {
  const discount = calculateDiscount(item.discount.value, item.quantity.value);
  const SingleTotalWithTax = calculateSingleTotalWithTax(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  const TaxTotal = calculateTaxTotal(
    item.quantity.value,
    item.unit_price.value,
    item.discount.value,
    item.product.value.tax_value
  );
  return {
    title: item.title.value,
    title_state: item.title_state.value,
    product_id: item.product.value.id,
    quantity: item.quantity.value,
    price_type: item.price_type.value,
    unit_price: item.unit_price.value,
    tax_id: item.product.value.tax_id,
    tax_total: TaxTotal,
    discount: discount,
    total: SingleTotalWithTax,
  };
};

const getReturnInvoiceValues = (state) => {
  const {
    b_invoice_nr,
    store_id,
    customer_id,
    invoice_billing_date,
    saled_by,
  } = state.invoice.value;

  const ifCash = () =>
    state.paid_type.value === "cash" || state.paid_type.value === "postpay";

  return {
    b_invoice_nr: b_invoice_nr,
    store_id: store_id,
    customer_id: customer_id,
    invoice_billing_date: +invoice_billing_date,
    invoice_date: getUnixTime(state.invoice_date.value),
    paid_type: state.paid_type.value,
    check_nr: state.check_nr.value,
    check_date: ifCash() ? "" : getUnixTime(state.check_date.value),
    bank_id: ifCash() ? "" : state.bank_id.value,
    description: state.description.value,
    saled_by: saled_by,
  };
};

const getReturnProductValues = (state) => {
  const {
    item_id,
    product_id,
    unit_price,
    tax_id,
    tax_value,
    discount,
    quantity,
  } = state.product.value;
  const quantityToReturn = state.quantity.value;

  // getting discount per quantity from the totalDiscount/totalQuantity
  const reversedDiscount = +discount / +quantity;

  const TaxTotal = calculateTaxTotal(
    quantityToReturn,
    unit_price,
    reversedDiscount,
    tax_value
  );

  const SingleTotalWithTax = calculateSingleTotalWithTax(
    quantityToReturn,
    unit_price,
    reversedDiscount,
    tax_value
  );

  return {
    item_id: item_id,
    product_id: product_id,
    tax_id: tax_id,
    quantity: quantityToReturn,
    unit_price: unit_price,
    discount: reversedDiscount * quantityToReturn,
    tax_total: TaxTotal,
    total: SingleTotalWithTax,
  };
};
const GeneralFiresnake = (err) => {
  return (dispatch) => {
    if (err.status === 401 || err.status === 403) {
      return dispatch(fireSnake("error", i18n.t("common:unaothurized")));
    }
    if (err.status === 500) {
      return dispatch(fireSnake("warning", "خطأ كود 500"));
    }
    dispatch(fireSnake("error", err.message || i18n.t("common:uncommonerror")));
  };
};

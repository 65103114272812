import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const Page404 = () => {
  const { isAuth } = useSelector((state) => state.profile);
  const _to = isAuth ? "/" : "/login";

  return <Redirect to={_to} />;
};

export default Page404;

import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";

// import { fireSnake } from "./globalsnakebar";
// import i18n from "../../i18n";

// const loading = () => {
//   return {
//     type: actionTypes.STATISTICS_LOADING,
//   };
// };

// const itemLoading = () => {
//   return {
//     type: actionTypes.ITEM_LOADING,
//   };
// };

const setErrors = (err) => {
  return {
    type: actionTypes.STATISTICS_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};
/**
 * Generic Bar stats
 */
export const getGenericYearStats = (type) => {
  return async (dispatch, getState) => {
    try {
      const { profile } = getState();

      let url = `/chart/${type}/yearly`;
      if (!profile.permissions.includes("chart")) {
        url += `/${profile.user_id}`;
      }

      const res = await axios.get(url);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getGenericMonthlyStats = (type, year) => {
  return async (dispatch, getState) => {
    try {
      const { profile } = getState();

      let url = `/chart/${type}/monthly/${year}`;
      if (!profile.permissions.includes("chart")) {
        url += `/${profile.user_id}`;
      }
      const res = await axios.get(url);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

/**
 * Fiscal
 */
export const getFiscalYearStats = () => {
  return async (dispatch, getState) => {
    try {
      const { profile } = getState();

      let url = `/chart/fiscal/yearly`;
      if (!profile.permissions.includes("chart")) {
        url += `/${profile.user_id}`;
      }

      const res = await axios.get(url);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};
export const getFiscalMonthlyStats = (year) => {
  return async (dispatch, getState) => {
    try {
      const { profile } = getState();

      let url = `/chart/fiscal/monthly/${year}`;
      if (!profile.permissions.includes("chart")) {
        url += `/${profile.user_id}`;
      }
      const res = await axios.get(url);
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

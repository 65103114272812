import React from "react";
import { Skeleton } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const PieSkeleton = () => {
  const { t } = useTranslation();
  return (
    <div className="flex__centerlize m-1">
      <Skeleton
        variant="circle"
        width={280}
        height={280}
        className="flex__centerlize"
        style={{ display: "flex" }}
      >
        {t("common:loading")}
      </Skeleton>
    </div>
  );
};

export default PieSkeleton;
